<template>
  <div class="frontend">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div
      v-if="!pageNotFound"
      class="page-content p4a__activity-content"
    >
      <header class="p4a__header">
        <b-container>
          <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <router-link
              :to="'/appointments'"
              :class="isMobile() ? 'mx-auto' : ''"
            >
              <img :src="isMobile() ? mobLogoImg : logoImg">
            </router-link>
            <!-- /Brand logo-->
          </b-row>
        </b-container>
      </header>

      <div class="breadcrumb-block breadcrumb__mobile mobile_res-breadcrumb">
        <b-container>
          <b-row>
            <b-col>
              <b-breadcrumb class="p4a__breadcrumb">
                <b-breadcrumb-item>
                  <router-link :to="'/appointments'">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                        fill="white"
                      />
                      <mask
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="1"
                        width="13"
                        height="13"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.1003 5.29305L7.85033 1.21435C7.61699 1.03955 7.32533 1.03955 7.15033 1.21435L1.90033 5.29305C1.72533 5.40959 1.66699 5.58439 1.66699 5.75919V12.1686C1.66699 13.1591 2.42533 13.9166 3.41699 13.9166H11.5837C12.5753 13.9166 13.3337 13.1591 13.3337 12.1686V5.75919C13.3337 5.58439 13.2753 5.40959 13.1003 5.29305ZM8.66699 8.08987V12.7512H6.33366V8.08987H8.66699ZM11.5837 12.7512C11.9337 12.7512 12.167 12.5182 12.167 12.1686V6.05052L7.50033 2.43796L2.83366 6.05052V12.1686C2.83366 12.5182 3.06699 12.7512 3.41699 12.7512H5.16699V7.5072C5.16699 7.1576 5.40033 6.92453 5.75033 6.92453H9.25032C9.60032 6.92453 9.83366 7.1576 9.83366 7.5072V12.7512H11.5837Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0)">
                        <rect
                          x="0.5"
                          y="0.5"
                          width="14"
                          height="14"
                          fill="black"
                        />
                      </g>
                    </svg>
                  </router-link>
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  class="p4a__breadcrumb-item"
                  active
                >
                  Service Form 礼仪服务预约表格
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div
        v-if="!isMobile()"
        ref="stepper_block"
        class="step-block"
      >
        <b-container>
          <b-row>
            <b-col>
              <b-breadcrumb>
                <b-breadcrumb-item
                  class="active"
                  @click="changePhaseFromBreadcrumb('customerDetail')"
                >
                  <div class="item_info breadcrumb-item__width-adjust">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="UserIcon" />
                    </div>
                    <div
                      class="item-text p4a__activity-item_text"
                    >
                      <h4>Details of Applicant</h4>
                      <p>
                        Enter your details
                      </p>
                    </div>
                  </div>
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-if="customFieldsLength"
                  :class="{ active: (phase == 'serviceDetail' || phase == 'previewSignPhase') }"
                  :disabled="!enableNextPhaseCustomer"
                  @click="changePhaseFromBreadcrumb('serviceDetail')"
                >
                  <div class="item_info breadcrumb-item__width-adjust">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="BoxIcon" />
                    </div>
                    <div class="item-text p4a__activity-item_text">
                      <h4>Service requests</h4>
                      <p />
                      <p>
                        Choose your service details
                      </p>
                    </div>
                  </div>
                </b-breadcrumb-item>

                <b-breadcrumb-item
                  :class="{ active: phase === 'previewSignPhase' }"
                  :disabled="!enableNextPhaseServiceDetail"
                  @click="changePhaseFromBreadcrumb('previewSignPhase')"
                >
                  <div class="item_info">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="FeatherIcon" />
                    </div>
                    <div class="item-text p4a__activity-item_text">
                      <h4>Preview and Sign</h4>
                      <p>
                        Preview your service summary and sign to confirm
                      </p>
                    </div>
                  </div>
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div
        v-if="isMobile()"
        ref="stepper_block"
        class="step-block front-end breadcrumb-mobile__clone"
        :class="[(scrolled ? 'stepper_fixed' : ''), (isAncestralSidebarAction ? 'd-none' : '')]"
        @scroll="stepperHandleScroll"
      >
        <b-container>
          <b-row>
            <b-col>
              <b-breadcrumb>
                <b-breadcrumb-item
                  class="active"
                  @click="changePhaseFromBreadcrumb('customerDetail')"
                >
                  <div class="item_info">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="UserIcon" />
                    </div>
                    <div
                      v-if="phase == 'customerDetail'"
                      class="item-text p4a__activity-item_text"
                    >
                      <h4>Details of Applicant</h4>
                      <p>
                        Enter your details
                      </p>
                    </div>
                  </div>
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-if="customFieldsLength"
                  :class="{ active: (phase == 'serviceDetail' || phase == 'previewSignPhase') }"
                  :disabled="!enableNextPhaseCustomer"
                  @click="changePhaseFromBreadcrumb('serviceDetail')"
                >
                  <div class="item_info">
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="BoxIcon" />
                    </div>
                    <div
                      v-if="phase == 'serviceDetail'"
                      class="item-text p4a__activity-item_text"
                    >
                      <h4>Service requests</h4>
                      <p />
                      <p class="breadcrumb-long_text">
                        Choose your service details
                      </p>
                    </div>
                  </div>
                </b-breadcrumb-item>

                <b-breadcrumb-item
                  :class="{ active: phase === 'previewSignPhase' }"
                  :disabled="!enableNextPhaseServiceDetail"
                  @click="changePhaseFromBreadcrumb('previewSignPhase')"
                >
                  <div
                    class="item_info"
                  >
                    <div class="item_icon p4a__activity-item_icon">
                      <feather-icon icon="FeatherIcon" />
                    </div>
                    <div
                      v-if="phase == 'previewSignPhase'"
                      class="item-text p4a__activity-item_text"
                    >
                      <h4>Preview and Sign</h4>
                      <p class="breadcrumb-long_text">
                        Preview your service summary and sign to confirm
                      </p>
                    </div>
                  </div>
                </b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <div class="events event-register main-content">
        <b-form @submit.prevent="submitFullForm">
          <validation-observer
            ref="frontActivityBookingCreateForm"
          >
            <div v-show="phase == 'customerDetail'">
              <b-container>
                <b-row>
                  <b-col
                    lg="12"
                  >
                    <b-card class="mobile_res-first-step">
                      <b-card-text>
                        <h3 class="mb-75 p4a__input-specifier__title">
                          Details of Applicant 申请人资料
                        </h3>
                        <p class="mb-75 p4a__input-specifier__sub-title">
                          We will use your contact details to keep you updated regarding further details about the event registered.
                        </p>

                        <b-row>
                          <b-col md="6">
                            <div class="form-row form-group">
                              <label class="col-md-6 col-form-label p4a__text-bold-gray">Applicant Name 申请人姓名*</label>
                              <div class="col">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Applicant Name"
                                  vid="h-first-name"
                                  rules="required"
                                >
                                  <b-form-input
                                    id="h-first-name"
                                    v-model="customerName"
                                    class="mobile-input__value-adjust p4a__activity-input"
                                    placeholder="First name and last name"
                                    :state="(errors.length > 0 || customerNameValidation) ? false : null"
                                    name="customerName"
                                    @input="validateCustomerName"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                  <small
                                    v-if="customerNameValidation"
                                    class="text-danger"
                                  >
                                    {{ customerNameError }}
                                  </small>
                                </validation-provider>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <div class="form-row form-group">
                              <label class="col-md-6 col-form-label p4a__text-bold-gray">Mobile Number 联络号码*</label>
                              <div class="col">
                                <!-- <validation-provider
                                  #default="{ errors }"
                                  name="Applicant Mobile"
                                  vid="h-phone-number"
                                  rules="required|digits:8"
                                >
                                  <b-form-input
                                    id="h-phone-number"
                                    v-model="customerContact"
                                    class="form-control mobile-input__value-adjust p4a__activity-input"
                                    placeholder="(65)"
                                    :state="(errors.length > 0 || customerContactValidation) ? false : null"
                                    name="customerContact"
                                    @input="validateCustomerContact"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                  <small
                                    v-if="customerContactValidation"
                                    class="text-danger"
                                  >
                                    {{ customerContactError }}
                                  </small>
                                </validation-provider> -->

                                <validation-provider
                                  #default="{ }"
                                  name="Applicant Mobile"
                                  vid="h-phone-number"
                                  rules=""
                                >
                                  <cleave
                                    id="h-phone-number"
                                    v-model="customerContact"
                                    class="form-control mobile-input__value-adjust p4a__activity-input"
                                    :raw="false"
                                    :options="cleaveOptions.prefix"
                                    placeholder="(65) 8 Digits Phone Number"
                                    @input="validateCustomerContact"
                                  />
                                  <small
                                    v-if="customerContactValidation"
                                    class="text-danger"
                                  >
                                    {{ customerContactError }}
                                  </small>
                                </validation-provider>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <div class="form-row form-group">
                              <label class="col-md-6 col-form-label p4a__text-bold-gray">Email 电邮</label>
                              <div class="col">
                                <validation-provider
                                  #default="{ errors }"
                                  name="Applicant Email"
                                  vid="h-customer-email"
                                  rules="email"
                                >
                                  <b-form-input
                                    id="h-customer-email"
                                    v-model="customerEmail"
                                    class="mobile-input__value-adjust p4a__activity-input"
                                    placeholder="Eg. johndoe@gmail.com"
                                    :state="(errors.length > 0 || customerEmailValidation) ? false : null"
                                    name="customerEmail"
                                    @input="validateCustomerEmail"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                  <small
                                    v-if="customerEmailValidation"
                                    class="text-danger"
                                  >
                                    {{ customerEmailError }}
                                  </small>
                                </validation-provider>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <hr class="p4a__border-base">
                        <h3 class="p4a__input-specifier__title">
                          Particulars of Deceased 先人资料
                        </h3>
                        <p />

                        <b-row>
                          <b-col md="7">
                            <b-form-group
                              label="Is the service applicable to multiple deceased? 服务是否用于多名先人?"
                              label-for="h-agent-name"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="12"
                              class="mb-0 custom-checkbox"
                            >
                              <!-- <b-form-checkbox-group
                                v-model="multipleDeceased"
                                name=""
                                @input="makeCheckboxToRadio()"
                              >
                                <b-form-checkbox value="Yes">
                                  Yes 是
                                </b-form-checkbox>
                                <b-form-checkbox value="No">
                                  No 否
                                </b-form-checkbox>
                              </b-form-checkbox-group> -->
                              <b-form-radio-group
                                v-model="multipleDeceased"
                                name="multipleDeceased"
                                class="form-custom-input-checkbox regular ml-50 p4a__checkbox-grp__bg"
                                :options="multipleDeceasedOptions"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                              label="Name of Deceased 先人名讳"
                              label-for="h-name-of-deceased"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-form-input
                                id="h-name-of-deceased"
                                v-model="deceasedName"
                                class="mobile-input__value-adjust p4a__activity-input"
                                type="text"
                                :placeholder="multipleDeceased == 'No' ? 'First name and last name' : 'Deceased 1, Deceased 2, ...'"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Age 享年"
                              label-for="h-age"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-form-input
                                id="h-age"
                                v-model="deceasedAge"
                                class="mobile-input__value-adjust p4a__activity-input"
                                type="text"
                                :placeholder="multipleDeceased == 'No' ? '' : 'Enter age of deceased 1'"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                              label="Departed Date 逝世日期"
                              label-for="h-departed-date"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-input-group class="p4a__flat-pickr-wrapper">
                                <flat-pickr
                                  id="h-departed-date"
                                  v-model="departedDate"
                                  class="form-control flat-pickr-group mobile-input__value-adjust"
                                  :placeholder="multipleDeceased == 'No' ? 'dd/mm/yyyy' : 'Enter date of deceased 1'"
                                  :config="flatPickrConfig"
                                />
                                <b-input-group-append is-text>
                                  <feather-icon
                                    icon="CalendarIcon"
                                    class="cursor-pointer p4a__text-base-color"
                                    data-toggle
                                    size="18"
                                  />
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col
                            md="6"
                            class="mb-0"
                          >
                            <b-form-group
                              label="Gender"
                              label-for="deceasedGender"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                              class="mb-0"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Gender"
                                rules=""
                              >
                                <b-form-radio-group
                                  id="deceasedGender"
                                  v-model="deceasedGender"
                                  buttons
                                  button-variant="outline-primary"
                                  class="spaceBetweenRadio p4a__radio-input__grp"
                                >
                                  <b-form-radio
                                    v-for="(option, index) in genderOptions"
                                    :key="index"
                                    :value="option.value"
                                    class="mb-50 mobile-input__value-adjust"
                                    style="font-weight: 400;"
                                    :state="(errors.length > 0) ? false : null"
                                  >
                                    <feather-icon
                                      v-if="deceasedGender === option.value"
                                      icon="CheckIcon"
                                      class="mr-50 mobile-input__value-adjust"
                                      size="16"
                                    />
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <small
                                  v-if="deceasedGenderValidation"
                                  class="text-danger"
                                >
                                  {{ deceasedGenderError }}
                                </small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                              label="Niche No. 福位编号"
                              label-for="h-age"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-form-input
                                id="h-age"
                                v-model="deceasedNiche"
                                class="mobile-input__value-adjust p4a__activity-input"
                                placeholder="Eg. A-S5A-AA-01-002"
                                type="text"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6" />
                          <b-col md="6">
                            <b-form-group
                              label="Pedestal No. 牌位编号"
                              label-for="h-age"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-form-input
                                id="h-age"
                                v-model="deceasedPedestal"
                                class="mobile-input__value-adjust p4a__activity-input"
                                placeholder="Eg. A-S5A-AA-01-002"
                                type="text"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6" />
                          <b-col md="6">
                            <b-form-group
                              label="Anling Lot No. 安灵编号"
                              label-for="h-age"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-form-input
                                id="h-age"
                                v-model="deceasedAngling"
                                class="mobile-input__value-adjust p4a__activity-input"
                                placeholder="Eg. D2-02-278"
                                type="text"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col
                            v-if="multipleDeceased == 'Yes'"
                            md="12"
                            class="mt-2"
                          >
                            <b-alert
                              variant="warning"
                              show
                            >
                              <div class="alert-body d-flex">
                                <feather-icon
                                  icon="AlertCircleIcon"
                                  size="18"
                                  class="my-auto mr-1"
                                />
                                <div>
                                  <span class="weight-400 fs-14">A duty officer will be contacting you for additional information regarding the multiple deceased if needed.</span>
                                  <br>
                                  <span class="weight-400 fs-14">如有需要，值班人员将与您联系以获取更多关于多名先人的信息。</span>
                                </div>
                              </div>
                            </b-alert>
                          </b-col>
                        </b-row>
                        <hr class="p4a__border-base">
                        <h3 class="p4a__input-specifier__title">
                          Details of Agent 代理资料
                        </h3>
                        <p />
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                              label="Agent name 代理姓名"
                              label-for="h-agent-name"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-form-input
                                id="h-agent-name"
                                v-model="agentName"
                                class="mobile-input__value-adjust p4a__activity-input"
                                placeholder="Agent Name"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6" />
                          <b-col md="6">
                            <b-form-group
                              label="Agent Code 代理编号"
                              label-for="h-agent-code"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <b-form-input
                                id="h-agent-code"
                                v-model="agentCode"
                                class="mobile-input__value-adjust p4a__activity-input"
                                type="text"
                                placeholder="Agent Code"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6" />
                          <b-col md="6">
                            <b-form-group
                              label="Agent Mobile Number 代理号码"
                              label-for="h-agent-contact"
                              label-class="p4a__text-bold-gray"
                              label-cols-md="6"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Agent Mobile Number 代理号码"
                                vid="h-agent-contact"
                                rules=""
                              >
                                <!-- <b-form-input
                                  id="h-agent-contact"
                                  v-model="agentContact"
                                  class="mobile-input__value-adjust p4a__activity-input"
                                  placeholder="(65) 8 Digits Phone Number"
                                  :state="errors.length > 0 ? false : null"
                                  name="agentContact"
                                /> -->
                                <cleave
                                  id="h-phone-number"
                                  v-model="agentContact"
                                  class="form-control mobile-input__value-adjust p4a__activity-input"
                                  :raw="false"
                                  :options="cleaveOptions.prefix"
                                  placeholder="+65 8 Digits Phone Number"
                                  @input="validateAgentContact"
                                />
                                <small
                                  v-if="agentContactValidation"
                                  class="text-danger"
                                >
                                  {{ agentContactError }}
                                </small>
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </b-container>
              <b-nav
                class="wrap-border save-nav mob-btn__alignment"
              >
                <li
                  class="nav-item ml-auto"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    class="p4a__service-back-btn"
                    :to="{ name: 'appointments-front-end' }"
                  >
                    Cancel
                  </b-button>
                </li>
                <li
                  class="nav-item mr-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    class="p4a__process-btn"
                    :disabled="!enableNextPhaseCustomer"
                    @click="changePhase"
                  >
                    <feather-icon
                      icon="ArrowRightIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Proceed to next step</span>
                  </b-button>
                </li>
              </b-nav>
            </div>

            <div v-show="phase == 'serviceDetail'">
              <div
                class="container mobile_res-service-dropdown d-flex"
                style="position: unset;"
              >
                <b-dropdown
                  v-if="isMobile()"
                  :text="currentTabTitle"
                  style="font-size: 14px; background: #fff;"
                  right
                  class="dropdown-icon-wrapper p4a__service-dropdown-btn d-mob-custom-dropdown"
                >
                  <template #button-content>
                    <span class="">{{ currentTabTitle }}</span>
                    <feather-icon
                      icon="ChevronDownIcon"
                      class="ml-1"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="(tab, index) in serviceRequests"
                    :key="index"
                    @click="changeDropdownTitle(index)"
                  >
                    <span>{{ tab.title ? tab.title : `Service #${index + 1}` }}</span>
                    <feather-icon
                      v-if="serviceRequests.length > 1"
                      icon="Trash2Icon"
                      style="color: #d91b35 !important;"
                      class="ml-1"
                      @click="deleteServiceRequest(index)"
                    />
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  v-if="isMobile() && lastRequestFilled"
                  class="p4a__service-common-btn"
                  style="height: 40px; margin-left: 5px;"
                  @click="addServiceRequest"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
              <div
                class="bg-white"
                :class="isMobile() ? 'mt-0' : 'mt-0' "
              >
                <div class="container">
                  <b-tabs
                    v-model="currentTab"
                    pills
                    class="booking__content pr-tabs service-booking__tabs p4a__booking-tabs"
                    :nav-class="[isMobile() ? 'd-none' : '']"
                    @changed="tabChanged"
                  >
                    <!-- Tab: Account -->
                    <b-tab
                      v-for="(item, tabIndex) in serviceRequests"
                      :key="tabIndex"
                      lazy
                    >
                      <template #title>
                        <span class="d-none d-sm-inline">{{ item.title ? item.title : `Service #${tabIndex + 1}` }}</span>
                        <feather-icon
                          v-if="serviceRequests.length > 1"
                          v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                          v-b-tooltip.hover.v-dark
                          title="Delete"
                          icon="Trash2Icon"
                          style="color: #d91b35 !important;"
                          class="ml-1"
                          @click="deleteServiceRequest(tabIndex)"
                        />
                      </template>
                      <div>
                        <b-container class="p-0">
                          <b-row>
                            <b-col
                              lg="12"
                              class=""
                            >
                              <div class="sd-step-wrapper">
                                <div
                                  class="sd-service-wrapper"
                                >
                                  <div class="d-flex justify-content-between">
                                    <h5
                                      class="sd-step-title p4a__service-specifier__title mobile_res-second-step-title"
                                      :class="{'sd-step-opacity': item.categorySelected}"
                                    >
                                      Step 1 : Choose a service
                                    </h5>
                                    <b-button
                                      v-if="item.categorySelected"
                                      link
                                      type="button"
                                      class="pr-2 no-background sd-full-opacity p4a__service-back-btn"
                                      @click="showCategoryEditWarning(tabIndex)"
                                    >
                                      <feather-icon
                                        icon="EditIcon"
                                        class=""
                                        size="16"
                                      />
                                      <span
                                        v-if="!isMobile()"
                                        class="ml-25"
                                      >Edit this step
                                      </span>
                                    </b-button>
                                  </div>
                                  <div
                                    class="sd-service-single-block p4a__service-single-block"
                                    :class="{'sd-step-opacity': item.categorySelected}"
                                  >
                                    <b-form-radio-group
                                      id="serviceType"
                                      v-model="item.category"
                                      buttons
                                      button-variant="outline-primary"
                                      class="spaceBetweenRadio sd-option-button"
                                      @change="item.categorySelected = true; item.workFlow = ''; item.workFlowSelected = false; item.specialForm = false; item.specialSDform = false;"
                                    >
                                      <b-form-radio
                                        v-for="(option, index) in workFlowCategories"
                                        :key="index"
                                        :value="option._id"
                                        class="mb-50 mobile-input__value-adjust service-type__btn"
                                      >
                                        <feather-icon
                                          v-if="item.category == option._id"
                                          icon="CheckIcon"
                                          class="mr-50"
                                          size="16"
                                        />
                                        {{ option.name }}
                                      </b-form-radio>
                                    </b-form-radio-group>
                                  </div>
                                </div>
                                <hr class="p4a__border-base">
                                <div
                                  class="sd-service-wrapper"
                                >
                                  <div class="d-flex justify-content-between">
                                    <h5
                                      class="sd-step-title p4a__service-specifier__title mobile_res-second-step-title"
                                      :class="{'sd-step-opacity': (item.workFlowSelected || !item.category)}"
                                    >
                                      Step 2 : Choose a service type and add service details
                                    </h5>
                                    <b-button
                                      v-if="item.workFlowSelected"
                                      link
                                      type="button"
                                      class="pr-2 no-background p4a__service-back-btn"
                                      @click="item.workFlowSelected = !item.workFlowSelected"
                                    >
                                      <feather-icon
                                        icon="EditIcon"
                                        class=""
                                        size="16"
                                      />
                                      <span
                                        v-if="!isMobile()"
                                        class="ml-25"
                                      >Edit this step
                                      </span>
                                    </b-button>
                                  </div>
                                  <div
                                    v-show="item.category"
                                    class="sd-service-single-block p4a__service-single-block"
                                    :class="{'sd-step-opacity': item.workFlowSelected}"
                                  >
                                    <b-form-radio-group
                                      id="serviceTypeDetail"
                                      v-model="item.workFlow"
                                      buttons
                                      button-variant="outline-primary"
                                      class="spaceBetweenRadio sd-option-button"
                                      @change="generateTitle(tabIndex)"
                                    >
                                      <b-form-radio
                                        v-for="(option, index) in getWorkFlows(item.category)"
                                        :key="index"
                                        :value="option._id"
                                        class="mb-50 mobile-input__value-adjust service-type__btn"
                                      >
                                        <feather-icon
                                          v-if="item.workFlow == option._id"
                                          icon="CheckIcon"
                                          class="mr-50"
                                          size="16"
                                        />
                                        {{ option.name }}
                                      </b-form-radio>
                                    </b-form-radio-group>
                                  </div>
                                  <div
                                    v-show="item.workFlow && (!item.specialForm || item.specialSDForm)"
                                    class="sd-service-single-block p4a__service-single-block"
                                    :class="{'sd-step-opacity': item.workFlowSelected}"
                                  >
                                    <b-row>
                                      <b-col md="6">
                                        <b-form-group
                                          :label="item.specialSDForm ? 'Location 地点*' : 'Location 地点'"
                                          label-cols-md="6"
                                          label-for="location"
                                          label-class="p4a__text-bold-gray"
                                        >
                                          <validation-provider
                                            #default="{ errors }"
                                            name="Location"
                                            vid="location"
                                            :rules="item.specialSDForm ? 'required' : ''"
                                          >
                                            <b-form-input
                                              id="location"
                                              v-model="item.location"
                                              class="mobile-input__value-adjust p4a__activity-input"
                                              placeholder="Eg. Mandai"
                                              :state="(errors.length > 0) ? false : null"
                                              name="location"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>
                                      <b-col md="6" />
                                      <b-col md="6">
                                        <validation-provider
                                          #default="{ errors }"
                                          name="Date"
                                          vid="date"
                                          :rules="item.specialSDForm ? '' : 'required'"
                                        >
                                          <b-form-group
                                            :label="item.specialSDForm ? 'Date 日期' : 'Date 日期*'"
                                            label-cols-md="6"
                                            label-for="date"
                                            label-class="p4a__text-bold-gray"
                                            :class="{ 'is-invalid': errors.length }"
                                          >
                                            <b-input-group class="p4a__flat-pickr-wrapper">
                                              <flat-pickr
                                                id="date"
                                                v-model="item.date"
                                                class="form-control flat-pickr-group mobile-input__value-adjust"
                                                :placeholder="item.specialSDForm ? 'N/A' : 'Select a Date'"
                                                :config="flatPickrConfigWithoutTime"
                                              />
                                              <b-input-group-append is-text>
                                                <feather-icon
                                                  icon="CalendarIcon"
                                                  class="cursor-pointer p4a__text-base-color"
                                                  data-toggle
                                                  size="18"
                                                />
                                              </b-input-group-append>
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col cols="md-6" />

                                      <b-col md="6">
                                        <validation-provider
                                          #default="{ errors }"
                                          name="Time"
                                          vid="time"
                                          :rules="item.specialSDForm ? '' : 'required'"
                                        >
                                          <b-form-group
                                            :label="item.specialSDForm ? 'Time 时间' : 'Time 时间*'"
                                            label-cols-md="6"
                                            label-for="time"
                                            label-class="p4a__text-bold-gray"
                                            :class="{ 'is-invalid': errors.length }"
                                          >
                                            <b-input-group>
                                              <flat-pickr
                                                id="time"
                                                v-model="item.time"
                                                class="form-control flat-pickr-group mobile-input__value-adjust"
                                                :placeholder="item.specialSDForm ? 'N/A' : 'Select a Time'"
                                                :config="flatPickrConfigTime"
                                              />
                                              <b-input-group-append is-text>
                                                <feather-icon
                                                  icon="ClockIcon"
                                                  class="cursor-pointer p4a__text-base-color"
                                                  data-toggle
                                                  size="18"
                                                />
                                              </b-input-group-append>
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                      </b-col>
                                      <b-col cols="md-6" />

                                      <b-col md="6">
                                        <b-form-group
                                          label="Related File No. 相关资料号码"
                                          label-cols-md="6"
                                          label-for="fileNo"
                                          label-class="p4a__text-bold-gray"
                                        >
                                          <validation-provider
                                            #default="{ errors }"
                                            name="Related File No."
                                            vid="fileNo"
                                            rules=""
                                          >
                                            <b-form-input
                                              id="fileNo"
                                              v-model="item.fileNo"
                                              class="mobile-input__value-adjust p4a__activity-input"
                                              :placeholder="item.specialSDForm ? 'N/A' : 'Optional'"
                                              :state="(errors.length > 0) ? false : null"
                                              name="fileNo"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>
                                      <b-col cols="md-6" />
                                      <b-col md="12">
                                        <b-form-group
                                          label="Remark 备注"
                                          label-cols-md="3"
                                          label-for="remarks"
                                          label-class="p4a__text-bold-gray"
                                        >
                                          <validation-provider
                                            #default="{ errors }"
                                            name="Remarks"
                                            vid="remarks"
                                            rules=""
                                          >
                                            <b-form-textarea
                                              id="remarks"
                                              v-model="item.remarks"
                                              class="mobile-input__value-adjust p4a__activity-input"
                                              placeholder="Optional (for service type)"
                                              rows="3"
                                              :state="(errors.length > 0) ? false : null"
                                              name="remarks"
                                              :style="{
                                                marginLeft: !isMobile() ? '-8px' : null,
                                              }"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                    <b-button
                                      class="d-block ml-auto p4a__process-btn"
                                      :class="{'sd-step-opacity': item.workFlowSelected}"
                                      :disabled="(item.specialSDForm && !item.location) || (!item.specialSDForm && (!item.date || !item.time))"
                                      @click="item.workFlowSelected = true;"
                                    >
                                      Proceed to next step
                                    </b-button>
                                  </div>
                                </div>
                                <hr class="p4a__border-base">
                                <div
                                  v-if="item.specialForm"
                                  class="sd-service-wrapper"
                                >
                                  <div v-if="item.specialFormName == 'Pedestal Engraving 神主牌刻碑'">
                                    <div class="d-flex justify-content-between">
                                      <h5
                                        class="sd-step-title p4a__service-specifier__title mobile_res-second-step-title"
                                        :class="{'sd-step-opacity': item.formValidated}"
                                      >
                                        Step 3 : Fill in Ancestral Tablets Engraving Notice 牌位刻碑表格
                                      </h5>
                                      <b-button
                                        v-if="item.formValidated"
                                        link
                                        type="button"
                                        class="pr-2 no-background sd-full-opacity p4a__service-back-btn"
                                        @click="item.formValidated = false"
                                      >
                                        <feather-icon
                                          icon="EditIcon"
                                          class=""
                                          size="16"
                                        />
                                        <span
                                          v-if="!isMobile()"
                                          class="ml-25"
                                        >Edit this step
                                        </span>
                                      </b-button>
                                    </div>
                                    <div
                                      :class="{'sd-step-opacity': item.formValidated}"
                                    >
                                      <b-alert
                                        variant="success"
                                        class="mt-75"
                                        :show="isMobile()"
                                      >
                                        <div class="alert-body d-flex">
                                          <feather-icon
                                            icon="AlertCircleIcon"
                                            size="40"
                                            class="my-auto mr-1"
                                          />
                                          <span>Tap on blue field in the form to begin filling out the details. 请点击蓝色格子开始填写详细信息。</span>
                                        </div>
                                      </b-alert>
                                      <ancestral-tablets-engraving
                                        class="mt-2 pt-75"
                                        :digital-form="pedestalForm"
                                        :request-form="{customerName, customerContact, customerEmail, agentName, agentCode, agentContact, deceasedPedestal}"
                                        :top-spacing="0"
                                        :service-request="item"
                                        :index="tabIndex"
                                        @updateFormValidation="updateFormValidation"
                                        @updateAncestralSidebarActive="updateAncestralSidebarActive"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-else-if="item.specialSDForm"
                                  class="sd-service-wrapper"
                                >
                                  <h5
                                    class="sd-step-title p4a__service-specifier__title mobile_res-second-step-title"
                                    :class="{'sd-step-opacity': (!item.workFlowSelected || !item.category)}"
                                  >
                                    Step 3 : Choose Type of Sticker
                                  </h5>

                                  <div v-if="item.workFlowSelected">
                                    <div
                                      class="sd-single-service-description bg-white"
                                      style="gap: 10px;"
                                    >
                                      <p>福</p>
                                      <b-form-input
                                        v-model="item.blessingRemarks"
                                        class="mobile-input__value-adjust p4a__activity-input"
                                        placeholder="Add remarks"
                                        style="width:700px;"
                                      />
                                      <b-form-spinbutton
                                        v-model="item.blessingQuantity"
                                        inline
                                        class="spin-button-sd p4a__spin-btn"
                                        step="1"
                                        min="0"
                                      />
                                    </div>
                                    <div
                                      class="sd-single-service-description bg-white"
                                      style="gap: 10px;"
                                    >
                                      <p>壽</p>
                                      <b-form-input
                                        v-model="item.lifeRemarks"
                                        placeholder="Add remarks"
                                        class="mobile-input__value-adjust p4a__activity-input"
                                        style="width:700px;"
                                      />
                                      <b-form-spinbutton
                                        v-model="item.lifeQuantity"
                                        inline
                                        class="spin-button-sd p4a__spin-btn"
                                        step="1"
                                        min="0"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    v-show="lastRequestFilled"
                                    class="w-100"
                                  >
                                    <hr class="p4a__border-base">
                                    <b-button
                                      class="mt-75 d-block mx-auto p4a__add-service-btn"
                                      @click="addServiceRequest"
                                    >
                                      <feather-icon icon="PlusIcon" />
                                      Add another service
                                    </b-button>
                                  </div>
                                </div>
                                <div
                                  v-else
                                  class="sd-service-wrapper"
                                >
                                  <h5
                                    class="sd-step-title p4a__service-specifier__title mobile_res-second-step-title"
                                    :class="{'sd-step-opacity': (!item.workFlowSelected || !item.category)}"
                                  >
                                    Step 3 : Choose a service description
                                  </h5>
                                  <div
                                    v-if="item.workFlowSelected"
                                    class="sd-service-single-block p4a__service-single-block"
                                  >
                                    <b-row
                                      v-for="(opt, key) in customFields"
                                      :key="key"
                                    >
                                      <b-col
                                        v-if="opt.showInFrontend == 'yes'"
                                        cols="12"
                                        class="form-group-wrapper"
                                      >
                                        <div v-if="opt.type == 'title-and-text'">
                                          <h5 class="sd-service-description-title">
                                            {{ opt.label }}
                                          </h5>
                                          <p style="margin-bottom: 0;">
                                            {{ item.serviceDescription[key].text }}
                                          </p>
                                        </div>
                                        <b-form-group
                                          v-if="opt.type == 'short-answer'"
                                          :label="opt.label"
                                          :label-for="'event-custom-field-' + key"
                                          class="service-description"
                                          label-class="service-description-label p4a__text-bold-gray"
                                        >
                                          <validation-provider
                                            #default="{ errors }"
                                            :name="opt.label"
                                            :vid="'event-custom-field-' + key"
                                            :rules="opt.required == 'yes' ? 'required' : ''"
                                          >
                                            <b-form-input
                                              :id="'event-custom-field-' + key"
                                              v-model="item.serviceDescription[key].value"
                                              class="mobile-input__value-adjust p4a__activity-input"
                                              :placeholder="opt.label"
                                              :state="(errors.length > 0) ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>

                                        <b-form-group
                                          v-if="opt.type == 'long-answer'"
                                          :label="opt.label"
                                          :label-for="'event-custom-field-' + key"
                                          class="service-description"
                                          label-class="service-description-label p4a__text-bold-gray"
                                        >
                                          <validation-provider
                                            #default="{ errors }"
                                            :name="opt.label"
                                            :vid="'event-custom-field-' + key"
                                            :rules="opt.required == 'yes' ? 'required' : ''"
                                          >
                                            <b-form-textarea
                                              :id="'event-custom-field-' + key"
                                              v-model="item.serviceDescription[key].value"
                                              class="mobile-input__value-adjust p4a__activity-input"
                                              trim
                                              :placeholder="opt.label"
                                              :state="(errors.length > 0) ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </validation-provider>
                                        </b-form-group>
                                        <div
                                          v-if="opt.type == 'increment'"
                                          class="sd-single-service-description bg-white"
                                        >
                                          <p class="p4a__service-text-color">
                                            {{ opt.label }}
                                          </p>
                                          <b-form-group
                                            class="increment-decrement-block"
                                            :label-for="'event-custom-field-' + key"
                                            label-class="p4a__text-bold-gray"
                                          >
                                            <validation-provider
                                              #default="{ errors }"
                                              :name="opt.label"
                                              :vid="'event-custom-field-' + key"
                                              :rules="opt.required == 'yes' ? 'required' : ''"
                                            >
                                              <b-form-spinbutton
                                                :id="'event-custom-field-' + key"
                                                v-model="item.serviceDescription[key].value"
                                                inline
                                                class="spin-button-sd p4a__spin-btn"
                                                :state="(errors.length > 0) ? false : null"
                                                :step="opt.extraOptions[2].value"
                                                :min="opt.extraOptions[0].value"
                                                :max="opt.extraOptions[1].value"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </b-form-group>
                                        </div>

                                        <b-form-group
                                          v-if="opt.type == 'multi-select' && opt.multiSelectType == 'multiple'"
                                          :label="opt.label"
                                          :label-for="'event-custom-field-' + key"
                                          class="service-description"
                                          label-class="service-description-label p4a__text-bold-gray"
                                        >
                                          <validation-provider
                                            #default="validationContext"
                                            :name="opt.label"
                                            :rules="opt.required == 'yes' ? 'required' : ''"
                                          >

                                            <b-form-checkbox-group
                                              :id="'event-custom-field-' + key"
                                              v-model="item.serviceDescription[key].value"
                                              buttons
                                              button-variant="outline-primary"
                                              class="spaceBetweenRadio sd-option-button display-block-checkbox"
                                            >
                                              <b-form-checkbox
                                                v-for="(optn, kindex) in getUserOptions(opt.extraOptions)"
                                                :key="kindex"
                                                :value="optn.key"
                                                class="mb-50 customCheckbox service-type__btn"
                                              >
                                                <feather-icon
                                                  v-if="item.serviceDescription[key].value.includes(optn.key)"
                                                  icon="CheckIcon"
                                                  class="mr-50"
                                                  size="16"
                                                />
                                                <span class="align-middle">{{ optn.key }}</span>
                                              </b-form-checkbox>
                                            </b-form-checkbox-group>

                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </validation-provider>
                                        </b-form-group>

                                        <b-form-group
                                          v-if="opt.type == 'multi-select' && opt.multiSelectType == 'single'"
                                          :label="opt.label"
                                          :label-for="'event-custom-field-' + key"
                                          class="service-description"
                                          label-class="service-description-label p4a__text-bold-gray"
                                        >
                                          <validation-provider
                                            #default="validationContext"
                                            :name="opt.label"
                                            :rules="opt.required == 'yes' ? 'required' : ''"
                                          >
                                            <b-form-checkbox-group
                                              :id="'event-custom-field-' + key"
                                              v-model="item.serviceDescription[key].value"
                                              buttons
                                              button-variant="outline-primary"
                                              class="spaceBetweenRadio sd-option-button display-block-checkbox"
                                              @input="changeRadioInput(tabIndex, key)"
                                            >
                                              <b-form-checkbox
                                                v-for="(optn, kindex) in getUserOptions(opt.extraOptions)"
                                                :key="kindex"
                                                :value="optn.key"
                                                class="mb-50 customCheckbox service-type__btn"
                                              >
                                                <feather-icon
                                                  v-if="item.serviceDescription[key].value.includes(optn.key)"
                                                  icon="CheckIcon"
                                                  class="mr-50"
                                                  size="16"
                                                />
                                                <span class="align-middle">{{ optn.key }}</span>
                                              </b-form-checkbox>
                                            </b-form-checkbox-group>

                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </validation-provider>
                                        </b-form-group>

                                        <b-form-group
                                          v-if="opt.type == 'single-select'"
                                          :label="opt.label"
                                          :label-for="'event-custom-field-' + key"
                                          class="service-description"
                                          label-class="service-description-label"
                                        >
                                          <validation-provider
                                            #default="validationContext"
                                            :name="opt.label"
                                            :rules="opt.required == 'yes' ? 'required' : ''"
                                          >

                                            <b-form-checkbox-group
                                              :id="'event-custom-field-' + key"
                                              v-model="item.serviceDescription[key].value"
                                              buttons
                                              button-variant="outline-primary"
                                              class="spaceBetweenRadio sd-option-button display-block-checkbox"
                                              @input="changeRadioInput(tabIndex, key)"
                                            >
                                              <b-form-checkbox
                                                v-for="(optn, kindex) in requiredOptions"
                                                :key="kindex"
                                                :value="optn.value"
                                                class="mb-50 customCheckbox service-type__btn"
                                              >
                                                <feather-icon
                                                  v-if="item.serviceDescription[key].value.includes(optn.value)"
                                                  icon="CheckIcon"
                                                  class="mr-50"
                                                  size="16"
                                                />
                                                <span class="align-middle">{{ optn.text }}</span>
                                              </b-form-checkbox>
                                            </b-form-checkbox-group>

                                            <b-form-invalid-feedback>
                                              {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                          </validation-provider>
                                        </b-form-group>

                                        <validation-provider
                                          v-if="opt.type == 'dropdown'"
                                          #default="{ errors }"
                                          :name="opt.label"
                                          :vid="'event-custom-field-' + key"
                                          :rules="opt.required == 'yes' ? 'required' : ''"
                                        >
                                          <b-form-group
                                            :label="opt.label"
                                            class="service-description"
                                            label-class="service-description-label p4a__text-bold-gray"
                                            :label-for="'event-custom-field-' + key"
                                            :state="(errors.length > 0) ? false : null"
                                          >
                                            <v-select
                                              :id="'event-custom-field-' + key"
                                              v-model="item.serviceDescription[key].value"
                                              label="value"
                                              placeholder="Select From List"
                                              :options="opt.extraOptions"
                                              :reduce="value => value.key"
                                              :clearable="true"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                          </b-form-group>
                                        </validation-provider>
                                        <hr
                                          v-if="(!opt.parent && customFields[key + 1] && customFields[key + 1].parent != opt.id) || (opt.parent && customFields[key + 1] && customFields[key + 1].parent != opt.parent)"
                                          class="p4a__border-white"
                                        >
                                      </b-col>
                                    </b-row>
                                  </div>
                                  <div
                                    v-show="lastRequestFilled"
                                    class="w-100"
                                  >
                                    <hr class="p4a__border-base">
                                    <b-button
                                      class="mt-75 d-block mx-auto p4a__add-service-btn"
                                      @click="addServiceRequest"
                                    >
                                      <feather-icon icon="PlusIcon" />
                                      Add another service
                                    </b-button>
                                  </div>
                                </div>
                                <div
                                  v-if="item.specialForm"
                                  class="sd-service-wrapper"
                                >
                                  <div v-if="item.specialFormName == 'Pedestal Engraving 神主牌刻碑'">
                                    <hr class="p4a__border-base">
                                    <h5
                                      class="sd-step-title p4a__service-specifier__title mobile_res-second-step-title"
                                      :class="{'sd-step-opacity': !item.formValidated}"
                                    >
                                      Step 4 : Upload Photo
                                    </h5>
                                    <div v-if="item.formValidated">
                                      <p class="sd-step__sub-title p4a__input-specifier__sub-title">
                                        You can upload:
                                      </p>
                                      <ul class="mb-1">
                                        <li>Deceased’s photo to be included on pedestal (optional)</li>
                                        <li class="ml-2">
                                          Please ensure deceased photo are of high quality
                                        </li>
                                        <li class="ml-2">
                                          Images saved from WhatsApp are not typically not recommended
                                        </li>
                                        <li>Your own engraving format (for example, hand-written on paper)</li>
                                      </ul>
                                      <div style="padding: 20px; border-radius: 6px; background: #D6C691 !important;">
                                        <vue-dropzone
                                          :id="'event-images' + tabIndex"
                                          :ref="'myVueDropzoneComponent' + tabIndex"
                                          :options="dropzoneOptions"
                                          :use-custom-slot="true"
                                          style="border: 2px dashed #999;"
                                          @vdropzone-files-added="(...args) => processFile(...args, tabIndex)"
                                          @vdropzone-removed-file="(...args) => fileRemoved(...args, tabIndex)"
                                          @vdropzone-error="(...args) => handleError(...args, tabIndex)"
                                        >
                                          <div class="dropzone-custom-content">
                                            <h3 class="dropzone-custom-title p4a__text-base-color">
                                              Drop file here or click to upload
                                            </h3>
                                            <div class="subtitle p4a__text-base-color">
                                              Files should not be bigger than 20 mb. JPEG, PNG or PDF only.
                                            </div>
                                            <b-button
                                              type="button"
                                              class="mt-2 no-float p4a__service-common-btn"
                                            >
                                              Upload file
                                            </b-button>
                                          </div>
                                        </vue-dropzone>
                                      </div>
                                    </div>

                                    <div
                                      v-show="lastRequestFilled"
                                      class="w-100"
                                    >
                                      <hr class="p4a__border-base">
                                      <b-button
                                        class="mt-75 d-block mx-auto p4a__add-service-btn"
                                        @click="addServiceRequest"
                                      >
                                        <feather-icon icon="PlusIcon" />
                                        Add another service
                                      </b-button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-container>
                      </div>
                    </b-tab>
                    <template #tabs-end>
                      <b-button
                        v-show="lastRequestFilled"
                        class="p4a__add-service-btn"
                        style="height: 40px;"
                        @click="addServiceRequest"
                      >
                        <feather-icon icon="PlusIcon" />
                        Add another service
                      </b-button>
                    </template>
                  </b-tabs>
                </div>
              </div>
              <b-nav
                class="wrap-border save-nav mt-2 mob-btn__alignment"
              >
                <li
                  class="nav-item ml-auto"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    class="p4a__service-back-btn"
                    :to="{ name: 'appointments-front-end' }"
                  >
                    Cancel
                  </b-button>
                </li>
                <li
                  class="nav-item mr-1"
                >
                  <b-button
                    type="button"
                    class="p4a__process-btn"
                    :disabled="previewPageComputation"
                    @click="makePreviewPage"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Preview and sign</span>
                  </b-button>
                </li>
              </b-nav>
            </div>

            <b-container>
              <b-row v-if="phase == 'previewSignPhase'">
                <b-col
                  v-if="!isMobile()"
                  ref="sticky_sidebar"
                  md="3"
                >
                  <b-card
                    v-if="!hasSpecialForm"
                    no-body
                    class="sticky_sidebar"
                  >
                    <b-card-header class="p4a__base-bg">
                      <slot name="header">
                        <div class="d-flex">
                          <div class="ps-cd-header-avatar-section">
                            <b-avatar
                              size="40"
                              rounded="sm"
                              class="ps-avatar-bg"
                            >
                              <feather-icon
                                icon="FeatherIcon"
                              />
                            </b-avatar>
                          </div>
                          <div class="ps-cd-header-text-section">
                            <p>signing required</p>
                            <h5>Ready to Submit?</h5>
                          </div>
                        </div>
                      </slot>
                    </b-card-header>
                    <b-card-body class="px-0 d-form-sidebar_action-btn-group">
                      <slot name="body">
                        <b-button
                          class="d-block mx-auto p4a__process-btn"
                          :disabled="!customerSignature"
                          @click="showPDFOverlay"
                        >
                          <feather-icon
                            icon="FeatherIcon"
                            size="18"
                            class="mr-50"
                            style="vertical-align: middle;"
                          />
                          Submit the form(s)
                        </b-button>
                        <b-button
                          type="button"
                          class="mx-auto d-block mt-1 p4a__service-back-btn"
                          @click="phase = 'customerDetail'"
                        >
                          Back to editing
                        </b-button>
                      </slot>
                    </b-card-body>
                  </b-card>
                  <b-card
                    v-else
                    class="service-forms-card"
                    header-class="p4a__signing-header"
                  >
                    <template #header>
                      <div class="d-flex">
                        <div class="ps-cd-header-avatar-section">
                          <b-avatar
                            size="40"
                            rounded="sm"
                            class="ps-avatar-bg"
                          >
                            <feather-icon
                              icon="FeatherIcon"
                            />
                          </b-avatar>
                        </div>
                        <div class="ps-cd-header-text-section">
                          <p>signing required</p>
                          <h5>Ready to Submit?</h5>
                        </div>
                      </div>
                    </template>
                    <b-card-text
                      class="mb-3"
                    >
                      <span>There are {{ pedestalFormsCount + 1 }} forms that need to be signed in this process:</span>
                    </b-card-text>

                    <app-timeline class="sign-list">
                      <app-timeline-item
                        v-for="count in pedestalFormsCount"
                        :key="count"
                        :icon="pedestalSign[count - 1] == true ? 'CheckIcon' : 'FeatherIcon'"
                        variant="primary"
                        :class="{'timeline-item-click-active': (ancestralFormPreview[count - 1] && !serviceFormPreview), 'signed pb-2': pedestalSign[count - 1]}"
                        @click="makeAncestralFormPreview(count - 1); serviceFormPreview = false;"
                      >
                        <div
                          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between"
                        >
                          <div style="margin-top: -25px">
                            <small class="text-muted">Form {{ count }}</small>
                            <p class="mb-0 text-black fs-16 weight-500">
                              Ancestral Tablets Engraving Notice
                            </p>
                            <p class="mb-0 text-black fs-16 weight-500">
                              牌位刻碑表格
                            </p>
                          </div>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="link"
                            class="d-form-timeline-arrow-btn"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              class=""
                              size="20"
                            />
                          </b-button>
                        </div>
                      </app-timeline-item>
                      <app-timeline-item
                        v-if="customerSignature"
                        icon="CheckIcon"
                        variant="primary"
                        class="signed"
                        :class="{'timeline-item-click-active': serviceFormPreview}"
                        @click="serviceFormPreview = true; ancestralFormPreview = ancestralFormPreview.map(() => false)"
                      >
                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
                          <div style="margin-top: -20px">
                            <small class="text-muted">Form {{ pedestalFormsCount + 1 }}</small>
                            <p class="mb-0 text-black fs-16 weight-500">
                              Service Form
                            </p>
                            <p class="mb-0 text-black fs-16 weight-500">
                              礼仪服务预约表格
                            </p>
                          </div>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="link"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              class=""
                              size="20"
                            />
                          </b-button>
                        </div>
                      </app-timeline-item>
                      <app-timeline-item
                        v-else
                        icon="FeatherIcon"
                        variant="primary"
                        :class="{'timeline-item-click-active': serviceFormPreview}"
                        @click="serviceFormPreview = true; ancestralFormPreview = ancestralFormPreview.map(() => false)"
                      >
                        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
                          <div>
                            <small class="text-muted">Form {{ pedestalFormsCount + 1 }}</small>
                            <p class="mb-0 text-black fs-16 weight-500">
                              Service Form
                            </p>
                            <p class="mb-0 text-black fs-16 weight-500">
                              礼仪服务预约表格
                            </p>
                          </div>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="link"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              class=""
                              size="20"
                            />
                          </b-button>
                        </div>
                      </app-timeline-item>
                    </app-timeline>

                    <hr class="p4a__border-base">

                    <b-card-body
                      class="d-form-sidebar_action-btn-group"
                      style="padding: 0 0 10px 0;"
                    >
                      <slot name="body">
                        <b-button
                          class="d-block mx-auto p4a__process-btn"
                          :disabled="!customerSignature || (pedestalSign.filter(o => o == true).length != pedestalFormsCount)"
                          @click="showPDFOverlay"
                        >
                          <feather-icon
                            icon="FeatherIcon"
                            size="18"
                            class="mr-50"
                            style="vertical-align: middle;"
                          />
                          Submit the form(s)
                        </b-button>
                        <b-button
                          type="button"
                          class="mx-auto d-block mt-1 p4a__service-back-btn"
                          @click="phase = 'customerDetail'"
                        >
                          Back to editing
                        </b-button>
                      </slot>
                    </b-card-body>
                  </b-card>
                  <div
                    v-if="!isMobile() && serviceFormPreview && !customerSignature && isServiceTypeSignTopToolTip"
                    class="ps-tooltip-card ps-tooltip-position-adjust"
                    :style="{ right: isServiceTypeSignTopToolTip ? '-10%' : null, left: isServiceTypeSignTopToolTip ? 'unset' : null, zIndex: isServiceTypeSignTopToolTip ? '11' : null}"
                  >
                    <b-card>
                      <b-card-header class="bg-warning">
                        <slot name="header">
                          <div
                            class="d-flex justify-content-between"
                            style="width: 100%"
                          >
                            <p>Signature Required</p>
                            <p
                              v-if="hasSpecialForm"
                              class="ml-auto"
                            >{{ pedestalFormsCount + 1 }}/{{ pedestalFormsCount + 1 }}</p>
                          </div>
                        </slot>
                      </b-card-header>
                      <b-card-text>
                        <p>Signature is required to proceed.</p>
                        <p>需要签名方可继续。</p>
                        <b-button
                          class="ps-tooltip-button"
                          @click="goToSignElement('bottom')"
                        >
                          Bring me over
                        </b-button>
                      </b-card-text>
                    </b-card>
                  </div>
                </b-col>

                <b-col
                  v-show="serviceFormPreview"
                  md="9"
                >
                  <div class="ps-main-content-wrapper p4a__base-bg d-form_bg-white mobile_res-white-bg">
                    <b-card class="m-0">
                      <b-card-text>
                        <div class="orn-form-header d-flex justify-content-between mb-75">
                          <div class="d-form-logo_section" />
                          <div class="d-form-no_section text-right">
                            <h5>
                              {{ serviceFormView.formNumber || 'NV-SG-NVC-FS01' }}
                            </h5>
                            <h5>
                              {{ serviceFormView.revision || 'REV: 1' }}
                            </h5>
                          </div>
                        </div>
                        <!-- <div class="d-flex">
                          <div
                            class="urn_repository-company-details"
                          >
                            <div class="urn_repository-company-title">
                              <h2>
                                <span>
                                  富貴山莊
                                </span>
                                <br>
                                Mount Prajna Ltd.
                              </h2>
                            </div>
                            <div class="urn_repository-company-tagline">
                              <p> (Co. Reg. No. 200401183W)</p>
                              <p>(GST Group Reg. No. M90364395L)</p>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="service-form_title-block">
                          <h4>
                            SERVICE FORM 礼仪服务预约表格
                          </h4>
                        </div> -->
                        <div class="b-form-section-title my-2">
                          <h3 class="mb-50 fs-16">
                            SERVICE FORM <span>礼仪服务预约表格</span>
                          </h3>
                        </div>
                        <b-table-simple
                          borderless
                          responsive
                          class="particulars_of_deceased d-none d-md-block ps-simple-table service-table__td-black"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-th colspan="2">
                                Applicant Details
                              </b-th>
                              <b-th colspan="2">
                                Particulars of Deceased
                              </b-th>
                            </b-tr>
                            <b-tr>
                              <b-td width="200px">
                                Applicant 报名者:
                              </b-td>
                              <b-td width="200px">
                                <span class="text-primary">{{ customerName }}</span>
                              </b-td>
                              <b-td width="200px">
                                Niche No. 福位编号:
                              </b-td>
                              <b-td>
                                <span class="text-primary">{{ deceasedNiche || '-' }}</span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td width="200px">
                                Contact No. 联络号码:
                              </b-td>
                              <b-td width="200px">
                                <span class="text-primary">(65) {{ customerContact }}</span>
                              </b-td>
                              <b-td width="200px">
                                Pedestal No. 牌位编号:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedPedestal || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td width="200px">
                                Email 电邮:
                              </b-td>
                              <b-td width="200px">
                                <span class="text-primary">{{ customerEmail || '-' }}</span>
                              </b-td>
                              <b-td width="200px">
                                Anling Lot No. 安灵编号:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedAngling || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td />
                              <b-td />
                              <b-td width="200px">
                                Name of Deceased 先人名讳:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedName || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td />
                              <b-td />
                              <b-td width="200px">
                                Departed Date 逝世日期:
                              </b-td>
                              <b-td><span class="text-primary">{{ departedDate || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td />
                              <b-td />
                              <b-td width="200px">
                                Age 年龄:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedAge || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td />
                              <b-td />
                              <b-td width="200px">
                                Gender 性别:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedGender || '-' }}</span></b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>

                        <b-table-simple
                          borderless
                          responsive
                          class="d-block d-md-none ps-simple-table service-table__td-black"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-th>
                                Applicant Details
                              </b-th>
                              <b-th />
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Applicant 报名者:
                              </b-td>
                              <b-td><span class="text-primary">{{ customerName }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Contact No. 联络号码:
                              </b-td>
                              <b-td><span class="text-primary">(65) {{ customerContact }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Email 电邮:
                              </b-td>
                              <b-td><span class="text-primary">{{ customerEmail || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>
                                Particulars of Deceased
                              </b-th>
                              <b-th />
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Niche No. 福位编号:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedNiche || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Pedestal No. 牌位编号:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedPedestal || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Anling Lot No. 安灵编号:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedAngling || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Name of Deceased 先人名讳:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedName || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Departed Date 逝世日期:
                              </b-td>
                              <b-td><span class="text-primary">{{ departedDate || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Age 年龄:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedAge || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>
                                Gender 性别:
                              </b-td>
                              <b-td><span class="text-primary">{{ deceasedGender || '-' }}</span></b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>

                        <div class="ps-service-type-block">
                          <h4 class="ps-service-type-title">
                            Service Type & Descriptions 服务类别和项目说明
                          </h4>
                          <div
                            v-for="(val, ind) in groupedCategory"
                            :key="ind"
                          >
                            <h6 class="ps-service-type-name">
                              {{ getCategoryName(val[0].category) }}
                            </h6>
                            <div
                              v-for="(request, requestIndex) in val"
                              :key="requestIndex"
                              class="ps-service-des-content-wrapper"
                            >
                              <div class="ps-service-des-block">
                                <div class="ps-service-des-checkbox-block">
                                  <b-form-checkbox
                                    v-model="checked"
                                    class="form-custom-input-checkbox"
                                  >
                                    <p>
                                      {{ request.title }}
                                    </p>
                                  </b-form-checkbox>
                                </div>
                                <div
                                  v-if="!request.specialForm"
                                  class="ps-service-des-content-block"
                                >
                                  <b-table-simple
                                    borderless
                                    responsive
                                    class="ps-simple-table service-table__td-black"
                                  >
                                    <b-tbody>
                                      <b-tr :class="{ 'd-flex flex-column' : isMobile() }">
                                        <b-td width="50%">
                                          Location 地点:
                                        </b-td>
                                        <b-td><span class="text-primary">{{ request.location || '-' }}</span></b-td>
                                      </b-tr>
                                      <b-tr
                                        v-if="!request.specialSDForm"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td width="50%">
                                          Service Date and Time 预定服务日期与时间:
                                        </b-td>
                                        <b-td><span class="text-primary">{{ request.date }}, {{ request.time }}</span></b-td>
                                      </b-tr>
                                      <b-tr
                                        v-if="!request.specialSDForm"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td width="50%">
                                          Related File No. 相关文件号码:
                                        </b-td>
                                        <b-td><span class="text-primary">{{ request.fileNo || '-' }}</span></b-td>
                                      </b-tr>
                                      <b-tr
                                        class="border-bottom"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td width="50%">
                                          Remark 备注:
                                        </b-td>
                                        <b-td><span
                                          class="text-primary"
                                          :class="{ 'service-width__adjust' : isMobile() }"
                                        >{{ request.remarks || '-' }}</span></b-td>
                                      </b-tr>
                                    </b-tbody>
                                    <b-tbody v-if="request.specialSDForm">
                                      <b-tr
                                        v-if="request.blessingQuantity"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td
                                          width="50%"
                                          style="vertical-align: top; padding: 5px 0px !important;"
                                        >
                                          福
                                        </b-td>
                                        <b-td>
                                          <span class="text-primary">x</span>
                                          <span
                                            class="green-text"
                                          >
                                            {{ request.blessingQuantity }}
                                          </span>
                                        </b-td>
                                      </b-tr>
                                      <b-tr
                                        v-if="request.blessingQuantity"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td
                                          width="50%"
                                          style="vertical-align: top; padding: 5px 0px !important;"
                                        >
                                          Remarks
                                        </b-td>
                                        <b-td>
                                          <span class="green-text">{{ request.blessingRemarks || '-' }}</span>
                                        </b-td>
                                      </b-tr>

                                      <b-tr
                                        v-if="request.lifeQuantity"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td
                                          width="50%"
                                          style="vertical-align: top; padding: 5px 0px !important;"
                                        >
                                          壽
                                        </b-td>
                                        <b-td>
                                          <span class="text-primary">x</span>
                                          <span
                                            class="green-text"
                                          >
                                            {{ request.lifeQuantity }}
                                          </span>
                                        </b-td>
                                      </b-tr>
                                      <b-tr
                                        v-if="request.lifeQuantity"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td
                                          width="50%"
                                          style="vertical-align: top; padding: 5px 0px !important;"
                                        >
                                          Remarks
                                        </b-td>
                                        <b-td>
                                          <span class="green-text">{{ request.lifeRemarks || '-' }}</span>
                                        </b-td>
                                      </b-tr>
                                    </b-tbody>
                                    <b-tbody v-if="(request.showServiceDescription && request.showServiceDescription == true)">
                                      <b-tr
                                        v-for="(opt, key) in request.serviceDescriptionValues"
                                        :key="key"
                                        :class="{ 'd-flex flex-column' : isMobile() }"
                                      >
                                        <b-td
                                          v-if="(opt.children && opt.children.length) || opt.value"
                                          width="50%"
                                          style="vertical-align: top; padding: 5px 0px !important;"
                                        >
                                          <span :class="{ 'service-width__adjust' : isMobile() }">{{ opt.key }}</span>
                                          <div>
                                            <span
                                              style="font-style: normal; font-weight: 400; font-size: 14px;"
                                              :class="{ 'service-width__adjust' : isMobile() }"
                                            >
                                              {{ opt.text }}
                                            </span>
                                          </div>
                                        </b-td>
                                        <b-td v-if="(opt.children && opt.children.length) || opt.value">
                                          <div v-if="opt.children">
                                            <div
                                              v-for="(child, indx) in opt.children"
                                              :key="indx"
                                            >
                                              <span
                                                v-if="Array.isArray(child.value)"
                                                :class="{ 'service-width__adjust' : isMobile(), 'd-flex' : !isMobile() }"
                                                class="text-primary"
                                              >
                                                <span
                                                  class="mr-50"
                                                >
                                                  {{ child.key }}
                                                </span>
                                                <div
                                                  v-if="child.value.length"
                                                >
                                                  <span
                                                    v-for="(option, index) in child.value"
                                                    :key="index"
                                                  >
                                                    {{ option }} <span v-if="index + 1 != child.value.length">, </span>
                                                  </span>
                                                </div>
                                                <div v-else>
                                                  <span>-</span>
                                                </div>
                                              </span>
                                              <span
                                                v-else
                                                :class="{ 'service-width__adjust' : isMobile(), 'd-flex' : !isMobile() }"
                                                class="text-primary"
                                              >
                                                <span
                                                  class="mr-50"
                                                >
                                                  {{ child.key }}
                                                </span>
                                                <div
                                                  v-if="child.value"
                                                  class="first-letter__primary"
                                                >
                                                  <span
                                                    v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                                                  >
                                                    {{ child.value }}
                                                  </span>
                                                  <span
                                                    v-else
                                                  >
                                                    {{ child.value }}
                                                  </span>
                                                </div>
                                                <div v-else>
                                                  <span>{{ child.type == 'title-and-text' ? '' : '-' }}</span>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            v-else
                                            class="text-primary"
                                          >
                                            <span
                                              v-if="Array.isArray(opt.value)"
                                              class="d-flex"
                                            >
                                              <div
                                                v-if="opt.value.length"
                                                :class="{ 'd-flex flex-column' : isMobile() }"
                                              >
                                                <span
                                                  v-for="(option, index) in opt.value"
                                                  :key="index"
                                                  :class="{ 'service-width__adjust' : isMobile() }"
                                                >
                                                  {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                                                </span>
                                              </div>
                                              <div v-else>
                                                <span>-</span>
                                              </div>
                                            </span>
                                            <span
                                              v-else
                                              class="d-flex text-primary"
                                            >
                                              <div v-if="opt.value">
                                                <span
                                                  v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                                                  class="green-text"
                                                  :class="{ 'service-width__adjust' : isMobile() }"
                                                >
                                                  {{ opt.value }}
                                                </span>
                                                <span
                                                  v-else
                                                  :class="{ 'service-width__adjust' : isMobile() }"
                                                >{{ opt.value }}</span>
                                              </div>
                                              <div v-else>
                                                <span>{{ opt.type == 'title-and-text' ? '' : '-' }}</span>
                                              </div>
                                            </span>
                                          </div>
                                        </b-td>
                                      </b-tr>
                                    </b-tbody>
                                  </b-table-simple>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="border-bottom" />
                          <b-row class="m-0">
                            <b-col
                              cols="12"
                              sm="4"
                              md="4"
                              lg="4"
                              class="border-right p-0"
                              :class="{ 'border-bottom' : isMobile() }"
                            >
                              <div class="ps-notice-left-section">
                                <b-row class="m-0">
                                  <b-col
                                    lg="12"
                                    class="p-0"
                                  >
                                    <p>Agent Name 代理姓名:</p>
                                    <p class="ps-notice-field-value">
                                      {{ agentName || '-' }}
                                    </p>
                                  </b-col>
                                  <b-col
                                    lg="12"
                                    class="p-0"
                                  >
                                    <p>Agent Code 代理编号:</p>
                                    <p class="ps-notice-field-value">
                                      {{ agentCode || '-' }}
                                    </p>
                                  </b-col>
                                  <b-col
                                    lg="12"
                                    class="p-0"
                                  >
                                    <p>
                                      Agent Mobile number
                                      <br>
                                      <span>代理联络号码:</span>
                                    </p>
                                    <p class="ps-notice-field-value">
                                      {{ agentContact ? `(65)${agentContact}` : '-' }}
                                    </p>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                            <b-col
                              cols="12"
                              sm="8"
                              md="8"
                              lg="8"
                              class="p-0"
                            >
                              <div class="ps-notice-right-section">
                                <h5>Notice 备注</h5>
                                <ul>
                                  <li>
                                    Payment and Reservation is to be done 3 days in advance.
                                    <br>
                                    <span>预购是指三天前完成订货与缴付金额。</span>
                                  </li>
                                  <li>
                                    Rental of premises is a must for chanting duration more than an hour.
                                    <br>
                                    <span>诵经时间一小时以上必需租用场地</span>
                                  </li>
                                  <li>
                                    Rental of burning cage is required if there is a large amount of paper offerings. (eg. 8’ x 16’ of paper house or 12 offering chests and above.)
                                    <br>
                                    <span>若焚烧大量纸钱，衣箱（12个衣箱或超过）或纸屋（限制纸屋面积8尺 x 16尺）必需提出申请租用化宝架。</span>
                                  </li>
                                  <li>
                                    Cheque shall be made payable to <b>“Mount Prajna Ltd”</b>
                                    <br>
                                    <span>支票上请注明交于</span>
                                    <b>“Mount Prajna Ltd”</b>
                                  </li>
                                  <li>
                                    All prices are inclusive GST.
                                    <br>
                                    <span>以上价格已含消费税。</span>
                                  </li>
                                </ul>
                              </div>
                            </b-col>
                          </b-row>
                        </div>

                        <div class="authorized-signature ps-authorized-signature mt-3">
                          <div
                            v-if="!isMobile() && !customerSignature && !isServiceTypeSignTopToolTip"
                            class="ps-tooltip-card ps-tooltip-position-adjust"
                            style="margin-left: -25px;"
                          >
                            <b-card>
                              <b-card-header class="bg-warning">
                                <slot name="header">
                                  <div
                                    class="d-flex justify-content-between"
                                    style="width: 100%"
                                  >
                                    <p>Signature Required</p>
                                    <p
                                      v-if="hasSpecialForm"
                                      class="ml-auto"
                                    >{{ pedestalFormsCount + 1 }}/{{ pedestalFormsCount + 1 }}</p>
                                  </div>
                                </slot>
                              </b-card-header>
                              <b-card-text>
                                <p>Signature is required to proceed.</p>
                                <p>需要签名方可继续。</p>
                                <b-button
                                  class="ps-tooltip-button"
                                >
                                  Bring me over
                                </b-button>
                              </b-card-text>
                            </b-card>
                          </div>
                          <div
                            v-if="!isMobile() && customerSignature && hasSpecialForm && pedestalSign.filter(i => i).length != pedestalFormsCount"
                            class="ps-tooltip-card ps-tooltip-position-adjust"
                            style="margin-left: -25px;"
                          >
                            <b-card>
                              <b-card-header class="bg-warning">
                                <slot name="header">
                                  <div
                                    class="d-flex justify-content-between"
                                    style="width: 100%"
                                  >
                                    <p>Signature Required</p>
                                    <p
                                      v-if="hasSpecialForm"
                                      class="ml-auto"
                                    >{{ pedestalSign.indexOf(false) + 1 }}/{{ pedestalFormsCount + 1 }}</p>
                                  </div>
                                </slot>
                              </b-card-header>
                              <b-card-text>
                                <p>Signature is required to proceed.</p>
                                <p>需要签名方可继续。</p>
                                <b-button
                                  class="ps-tooltip-button"
                                  @click="serviceFormPreview = !serviceFormPreview; changeFormOverview();"
                                >
                                  Bring me over
                                </b-button>
                              </b-card-text>
                            </b-card>
                          </div>
                          <b-row ref="signDiv">
                            <b-col
                              cols="12"
                              sm="6"
                              md="6"
                              lg="6"
                            >
                              <p class="d-form-signature-title">
                                Purchaser / Authorized Representative Signatory & Date
                                <br>
                                <span>购买者/受委托领瓮人签名和日期</span>
                              </p>
                              <div
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                class="form-signature-box"
                                :class="{'signed': customerSignature, 'form-signature-box__focus' : !customerSignature && ancestralNoticeSignBoxFocus}"
                                @click="openCustomerSignModal"
                              >
                                <div class="form-signature-content">
                                  <span
                                    v-if="!isSignature"
                                    class="form-signature-text"
                                  >
                                    Sign here
                                  </span>
                                  <b-img
                                    v-else
                                    :src="customerSignature"
                                    alt="authorized-sign"
                                  />
                                </div>
                              </div>
                              <div class="signature-date-block ps-signature-date-block">
                                <p>Name 姓名: {{ customerName }}</p>
                                <p v-if="customerSignature">
                                  Date 日期: {{ dateFormatWithTime() }}
                                </p>
                                <p v-else>
                                  Date 日期:
                                </p>
                              </div>
                            </b-col>
                          </b-row>
                        </div>

                        <div class="border mt-4 ps-bottom-box">
                          <h5 class="border-bottom text-center text-uppercase d-form-office-use-title">
                            For office use 公务用栏
                          </h5>
                          <b-row>
                            <b-col
                              cols="12"
                              sm="6"
                              md="6"
                              lg="6"
                              class="px-2 py-2 ps-authorized-signature"
                            >
                              <p>Service Staff 服务职员:</p>
                              <div
                                class="form-signature-box bg-transparent"
                              >
                                <div class="form-signature-content">
                                  <span
                                    class="form-signature-text"
                                  />
                                </div>
                              </div>
                              <div class="signature-date-block ps-signature-date-block">
                                <p>Name 姓名: </p>
                                <p>Date 日期: </p>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-card-text>
                    </b-card>
                  </div>
                  <b-card
                    v-if="isMobile()"
                    no-body
                    class="submit-form_mobile rounded-0"
                  >
                    <b-card-body
                      v-if="hasSpecialForm"
                      class="p-0"
                    >
                      <slot name="body">
                        <div class="mobile-sidebar__content-section">
                          <div class="mobile-sidebar__top-content d-flex justify-content-between">
                            <div>
                              <b-button
                                v-for="count in pedestalFormsCount"
                                :key="count"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                :class="{'mobile_sidebar-btn_click-active': (ancestralFormPreview[count - 1] && !serviceFormPreview), 'mobile_sidebar-btn_active' : pedestalSign[count - 1]}"
                                class="btn-icon mobile_sidebar-btn"
                                @click="makeAncestralFormPreview(count - 1); serviceFormPreview = false;"
                              >
                                <feather-icon
                                  v-if="!pedestalSign[count - 1]"
                                  icon="FeatherIcon"
                                />
                                <feather-icon
                                  v-else
                                  icon="CheckIcon"
                                />
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="btn-icon mobile_sidebar-btn"
                                :class="{'mobile_sidebar-btn_click-active': serviceFormPreview, 'mobile_sidebar-btn_active' : customerSignature}"
                                @click="serviceFormPreview = true"
                              >
                                <feather-icon
                                  v-if="!customerSignature"
                                  icon="FeatherIcon"
                                />
                                <feather-icon
                                  v-else
                                  icon="CheckIcon"
                                />
                              </b-button>
                            </div>
                            <div v-if="!customerSignature || pedestalSign.filter(item => item === false).length > 0">
                              <p class="mb-0">
                                Form(s) left to sign
                              </p>
                              <!-- <p class="mb-0">
                                还剩两份表格需签署
                              </p> -->
                              <p class="mb-0">
                                表格需签署
                              </p>
                            </div>
                            <!-- <div v-else-if="(!customerSignature && pedestalSign) || (customerSignature && !pedestalSign)">
                              <p class="mb-0">
                                1 form left to sign
                              </p>
                              <p class="mb-0">
                                还剩一份表格需签署
                              </p>
                            </div> -->
                            <div v-else>
                              <p class="mb-0">
                                All forms signed, please submit.
                              </p>
                              <p class="mb-0">
                                所有表格已签署，请提交
                              </p>
                            </div>
                          </div>
                          <div
                            v-if="!customerSignature && serviceFormPreview"
                            class="mobile-sidebar__bottom-content"
                          >
                            <div class="form-title_section">
                              <p>
                                Service Form 礼仪服务预约表格
                              </p>
                            </div>
                            <div class="form-btn_section">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="warning"
                                @click="openCustomerSignModal"
                              >
                                Sign now 立即签署
                              </b-button>
                            </div>
                          </div>
                          <div
                            v-if="pedestalSign.filter(item => item === false).length > 0 && !serviceFormPreview"
                            class="mobile-sidebar__bottom-content"
                          >
                            <div class="form-title_section">
                              <p>
                                Ancestral Tablets Engraving Notice 牌位刻碑表格
                              </p>
                            </div>
                            <div class="form-btn_section">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="warning"
                                @click="openPedestalSignModal"
                              >
                                Sign now 立即签署
                              </b-button>
                            </div>
                          </div>
                        </div>
                        <div class="mobile-sidebar__action-btn">
                          <p
                            @click="phase = 'customerDetail'"
                          >
                            Back to editing
                          </p>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success"
                            :disabled="!customerSignature"
                            @click="showPDFOverlay"
                          >
                            <feather-icon
                              icon="FeatherIcon"
                              size="18"
                              class="mr-50"
                              style="vertical-align: middle;"
                            />
                            Submit the form(s) 提交表格
                          </b-button>
                        </div>
                      </slot>
                    </b-card-body>
                    <b-card-body
                      v-else
                      class="p-0"
                    >
                      <slot name="body">
                        <div class="mobile-sidebar__content-section">
                          <div class="mobile-sidebar__top-content d-flex justify-content-between">
                            <div>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="btn-icon mobile_sidebar-btn mobile_sidebar-btn_click-active"
                                :class="{'mobile_sidebar-btn_active': customerSignature}"
                              >
                                <feather-icon
                                  v-if="!customerSignature"
                                  icon="FeatherIcon"
                                />
                                <feather-icon
                                  v-else
                                  icon="CheckIcon"
                                />
                              </b-button>
                            </div>
                            <div v-if="!customerSignature">
                              <p class="mb-0">
                                1 form left to sign
                              </p>
                              <p class="mb-0">
                                还剩一份表格需签署
                              </p>
                            </div>
                            <div v-else>
                              <p class="mb-0">
                                All form signed, please submit.
                              </p>
                              <p class="mb-0">
                                所有表格已签署，请提交
                              </p>
                            </div>
                          </div>
                          <div
                            v-if="!customerSignature"
                            class="mt-25 mobile-sidebar__bottom-content"
                          >
                            <div class="form-title_section">
                              <p>
                                Service Form 礼仪服务预约表格
                              </p>
                            </div>
                            <div class="form-btn_section">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="warning"
                                @click="openCustomerSignModal"
                              >
                                Sign now 立即签署
                              </b-button>
                            </div>
                          </div>
                        </div>
                        <div class="mobile-sidebar__action-btn">
                          <p
                            class="p4a__text-base-color"
                            @click="phase = 'customerDetail'"
                          >
                            Back to editing
                          </p>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="p4a__process-btn"
                            :disabled="!customerSignature"
                            @click="showPDFOverlay"
                          >
                            <feather-icon
                              icon="FeatherIcon"
                              size="18"
                              class="mr-50"
                              style="vertical-align: middle;"
                            />
                            Submit the form 提交表格
                          </b-button>
                        </div>
                      </slot>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col
                  v-show="!serviceFormPreview"
                  :md="isMobile() ? 12 : 9"
                >
                  <template
                    v-for="count in pedestalFormsCount"
                  >
                    <div
                      v-if="ancestralFormPreview[count - 1]"
                      :key="count"
                      class="ps-main-content-wrapper mobile_res-white-bg p4a__base-bg"
                    >
                      <ancestral-tablets-engraving-preview
                        :ref="'previewComponent' + (count - 1)"
                        :digital-form="pedestalForm"
                        :pedestal-form-values="pedestalFormValues[count - 1]"
                        :request-form="{customerName, customerContact, customerEmail}"
                        :files="uploadedFiles[count - 1]"
                        :signbox-focus="ancestralSignBoxFocus"
                        :form-index="count - 1"
                        @updateSignValidation="updateSignValidationWithIndex"
                      />
                      <div
                        v-if="!isMobile() && !pedestalSign[count - 1]"
                        class="ps-tooltip-card"
                        :style="`bottom: ${formOneMoveLength}%`"
                      >
                        <b-card>
                          <b-card-header class="bg-warning">
                            <slot name="header">
                              <div
                                class="d-flex justify-content-between"
                                style="width: 100%"
                              >
                                <p>Signature Required</p>
                                <p
                                  class="ml-auto"
                                >{{ count }}/{{ pedestalFormsCount + 1 }}</p>
                              </div>
                            </slot>
                          </b-card-header>
                          <b-card-text>
                            <p>Signature is required to proceed.</p>
                            <p>需要签名方可继续。</p>
                            <b-button
                              class="ps-tooltip-button"
                              @click="openAncestralSignModalWithIndex(count - 1)"
                            >
                              Bring me over
                            </b-button>
                          </b-card-text>
                        </b-card>
                      </div>

                      <div
                        v-if="!isMobile() && pedestalSign[count - 1] && customerSignature && pedestalSign.filter(i => i).length != pedestalFormsCount"
                        class="ps-tooltip-card"
                        :style="`bottom: ${formOneMoveLength}%`"
                      >
                        <b-card>
                          <b-card-header class="bg-warning">
                            <slot name="header">
                              <div
                                class="d-flex justify-content-between"
                                style="width: 100%"
                              >
                                <p>Signature Required</p>
                                <p
                                  class="ml-auto"
                                >{{ pedestalSign.indexOf(false) + 1 }}/{{ pedestalFormsCount + 1 }}</p>
                              </div>
                            </slot>
                          </b-card-header>
                          <b-card-text>
                            <p>Signature is required to proceed.</p>
                            <p>需要签名方可继续。</p>
                            <b-button
                              class="ps-tooltip-button"
                              @click="openAncestralSignModalWithIndex(pedestalSign.indexOf(false))"
                            >
                              Bring me over
                            </b-button>
                          </b-card-text>
                        </b-card>
                      </div>

                      <div
                        v-if="!isMobile() && pedestalSign[count - 1] && !customerSignature"
                        class="ps-tooltip-card"
                        :style="`bottom: ${formOneMoveLength}%`"
                      >
                        <b-card>
                          <b-card-header class="bg-warning">
                            <slot name="header">
                              <div
                                class="d-flex justify-content-between"
                                style="width: 100%"
                              >
                                <p>Signature Required</p>
                                <p
                                  class="ml-auto"
                                >{{ pedestalFormsCount + 1 }}/{{ pedestalFormsCount + 1 }}</p>
                              </div>
                            </slot>
                          </b-card-header>
                          <b-card-text>
                            <p>Signature is required to proceed.</p>
                            <p>需要签名方可继续。</p>
                            <b-button
                              class="ps-tooltip-button"
                              @click="serviceFormPreview = !serviceFormPreview; goToSignElement('bottom'); ancestralFormPreview = ancestralFormPreview.map(() => false)"
                            >
                              Bring me over
                            </b-button>
                          </b-card-text>
                        </b-card>
                      </div>
                    </div>
                  </template>
                  <b-card
                    v-if="isMobile()"
                    no-body
                    class="submit-form_mobile rounded-0"
                  >
                    <b-card-body
                      class="p-0"
                    >
                      <slot name="body">
                        <div class="mobile-sidebar__content-section">
                          <div class="mobile-sidebar__top-content d-flex justify-content-between">
                            <div>
                              <b-button
                                v-for="count in pedestalFormsCount"
                                :key="count"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                :class="{'mobile_sidebar-btn_click-active': (ancestralFormPreview[count - 1] && !serviceFormPreview), 'mobile_sidebar-btn_active': pedestalSign[count - 1]}"
                                class="btn-icon mobile_sidebar-btn"
                                @click="makeAncestralFormPreview(count - 1); serviceFormPreview = false;"
                              >
                                <feather-icon
                                  v-if="!pedestalSign[count - 1]"
                                  icon="FeatherIcon"
                                />
                                <feather-icon
                                  v-else
                                  icon="CheckIcon"
                                />
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="btn-icon mobile_sidebar-btn"
                                :class="{'mobile_sidebar-btn_click-active': serviceFormPreview, 'mobile_sidebar-btn_active': customerSignature}"
                                @click="serviceFormPreview = true"
                              >
                                <feather-icon
                                  v-if="!customerSignature"
                                  icon="FeatherIcon"
                                />
                                <feather-icon
                                  v-else
                                  icon="CheckIcon"
                                />
                              </b-button>
                            </div>
                            <div v-if="!customerSignature || pedestalSign.filter(item => item === false).length > 0">
                              <p class="mb-0">
                                Form(s) left to sign
                              </p>
                              <!-- <p class="mb-0">
                                还剩两份表格需签署
                              </p> -->
                              <p class="mb-0">
                                表格需签署
                              </p>
                            </div>
                            <!-- <div v-else-if="(!customerSignature && pedestalSign) || (customerSignature && !pedestalSign)">
                              <p class="mb-0">
                                1 form left to sign
                              </p>
                              <p class="mb-0">
                                还剩一份表格需签署
                              </p>
                            </div> -->
                            <div v-else>
                              <p class="mb-0">
                                All forms signed, please submit.
                              </p>
                              <p class="mb-0">
                                所有表格已签署，请提交
                              </p>
                            </div>
                          </div>
                          <div
                            v-if="!customerSignature && serviceFormPreview"
                            class="mobile-sidebar__bottom-content"
                          >
                            <div class="form-title_section">
                              <p>
                                Service Form 礼仪服务预约表格
                              </p>
                            </div>
                            <div class="form-btn_section">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="warning"
                                @click="openCustomerSignModal"
                              >
                                Sign now 立即签署
                              </b-button>
                            </div>
                          </div>
                          <div
                            v-if="pedestalSign.filter(item => item === false).length > 0 && !serviceFormPreview"
                            class="mobile-sidebar__bottom-content"
                          >
                            <div class="form-title_section">
                              <p>
                                Ancestral Tablets Engraving Notice 牌位刻碑表格
                              </p>
                            </div>
                            <div class="form-btn_section">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="button"
                                variant="warning"
                                @click="openPedestalSignModal"
                              >
                                Sign now 立即签署
                              </b-button>
                            </div>
                          </div>
                        </div>
                        <div class="mobile-sidebar__action-btn">
                          <p
                            @click="phase = 'customerDetail'"
                          >
                            Back to editing
                          </p>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success"
                            :disabled="!customerSignature"
                            @click="showPDFOverlay"
                          >
                            <feather-icon
                              icon="FeatherIcon"
                              size="18"
                              class="mr-50"
                              style="vertical-align: middle;"
                            />
                            Submit the form(s) 提交表格
                          </b-button>
                        </div>
                      </slot>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </validation-observer>
        </b-form>
      </div>

      <!-- customer signature modal -->
      <b-modal
        id="customer-sign-modal"
        ref="customer-sign-modal"
        modal-class="status-update-modal sign-modal-adjust"
        centered
        no-stacking
        no-close-on-backdrop
        header-class="front-modal"
        size="417"
        @ok="closeCustomerSignModal"
        @cancel="closeCustomerSignModal"
      >
        <template #modal-title>
          <span class="p4a__sign-modal-title pb-75"> Signature </span>
          <br>
          <span class="p4a__sign-modal-subtitle">Purchaser / Authorized Representative Signatory & Date</span>
        </template>
        <div class="">
          <VueSignaturePad
            ref="signaturePad"
            class="border p4a__sign-modal-content_border"
            width="317px"
            height="160px"
            :options="options"
          />
        </div>
        <template #modal-footer="{ cancel }">
          <b-button
            link
            class="mr-auto ml-0 pl-0 p4a__service-back-btn"
            @click="clear()"
          >
            Clear
          </b-button>
          <b-button
            class="p4a__service-common__outline-btn"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <b-button
            class="p4a__service-common-btn"
            @click="save()"
          >
            <span class="align-middle">Submit</span>
          </b-button>
        </template>
      </b-modal>
      <!-- ./ customer signature modal -->

      <b-modal
        id="submit-sign-form-modal"
        title=""
        centered
        no-stacking
        hide-footer
        header-class="front-modal"
        modal-class="sign-modal-adjust"
        style="max-width: 647px;"
      >
        <h3 class="p4a__sign-modal-title">
          Submit this signed form?
        </h3>
        <p class="p4a__sign-modal-subtitle">
          Please ensure that the information in the <strong>Service Form</strong> is accurate. A Duty Officer will be reviewing your request. A PDF copy will be sent to the applicant.
        </p>
        <p class="p4a__sign-modal-subtitle">
          Please confirm if the PDF will be send via:
        </p>
        <div class="mt-1">
          <b-button
            :class="!smsOnly ? 'p4a__sms-email_solid-btn' : 'p4a__sms-email_outline-btn'"
            @click="updateSmsOnly"
          >
            Email only
          </b-button>
          <b-button
            :class="smsOnly ? 'p4a__sms-email_solid-btn' : 'p4a__sms-email_outline-btn'"
            @click="updateSmsOnly"
          >
            SMS only
          </b-button>
          <div class="p4a__sms-email-content_border">
            <b-row class="p-1">
              <b-col
                v-if="smsOnly"
                md="12"
              >
                <strong class="p4a__sms-email-input-label">Mobile Number</strong>
                <b-form-input
                  v-model="customerContact"
                  class="mt-1 p4a__sms-email-input-value"
                />
              </b-col>
              <b-col
                v-else
                md="12"
              >
                <strong class="p4a__sms-email-input-label">Email</strong>
                <b-form-input
                  v-model="customerEmail"
                  class="mt-1 p4a__sms-email-input-value"
                />
              </b-col>
            </b-row>
          </div>
        </div>
        <div
          class="p4a__sms-email-bottom_text"
        >
          <small>
            Note: This is a password protected document.
          </small>
          <br>
          <small>
            The password format is customer’s mobile number (XXXXXXXX).
          </small>
        </div>
        <div class="float-right">
          <b-button
            class="p4a__service-common__outline-btn mr-2 my-auto"
            @click="$bvModal.hide('submit-sign-form-modal')"
          >
            No, back to editing
          </b-button>
          <b-button
            class="p4a__service-common-btn"
            :disabled="(smsOnly && !customerContact) || (!smsOnly && !customerEmail)"
            @click="submitFinalForm(); $bvModal.hide('submit-sign-form-modal')"
          >
            <feather-icon
              icon="ArrowRightIcon"
              class="mr-50"
              size="16"
            />
            Yes, Proceed
          </b-button>
        </div>
        <div class="clear" />
      </b-modal>
    </div>

    <not-found-error
      v-if="pageNotFound"
    />

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BContainer, BCol, BBreadcrumb, BBreadcrumbItem, BTab, BTabs, BFormInvalidFeedback, VBTooltip,
  BCard, BCardText, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BButton,
  BFormRadio, BFormRadioGroup, BForm, BFormSpinbutton, BFormCheckbox, BModal, BImg, BAlert, BDropdown, BDropdownItem,
  BFormCheckboxGroup, BNav, BCardHeader, BCardBody, BAvatar, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import NotFoundError from '../pages/error/NotFoundError.vue'
import Loader from './Loader.vue'
import AncestralTabletsEngraving from './AncestralTabletsEngraving.vue'
import AncestralTabletsEngravingPreview from './AncestralTabletsEngravingPreview.vue'

export default {
  components: {
    BForm,
    BRow,
    BContainer,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardText,
    BFormSpinbutton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BButton,
    BFormRadio,
    BFormCheckbox,
    BModal,
    BImg,
    BNav,
    BCardHeader,
    BCardBody,
    BFormCheckboxGroup,
    BAvatar,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAlert,
    BTab,
    BTabs,
    BFormInvalidFeedback,
    BDropdown,
    BDropdownItem,
    Cleave,
    vueDropzone: vue2Dropzone,
    flatPickr,
    Loader,
    NotFoundError,
    AncestralTabletsEngraving,
    AncestralTabletsEngravingPreview,
    AppTimeline,
    AppTimelineItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      formOneMoveLength: 59.7,
      ancestralSignBoxFocus: false,
      ancestralNoticeSignBoxFocus: false,
      isServiceTypeSignTopToolTip: true,
      hasSpecialForm: false,
      ancestralFormPreview: [],
      serviceFormPreview: false,
      isPurchaserSign: false,
      isAncestralSidebarAction: false,
      smsOnly: true,
      limitPosition: 195,
      scrolled: false,
      lastPosition: 0,
      bottomActionBlock: 0,
      phase: 'customerDetail',
      activity: {},
      pack: {},
      timeSlots: [],
      enabledDates: [],
      start: '',
      startValidation: false,
      startError: 'Valid date is required',
      startTime: '',
      startTimeValidation: false,
      startTimeError: 'Valid time is required',
      customerName: '',
      customerNameValidation: false,
      customerNameError: 'Valid name is required',
      customerContact: '',
      customerContactValidation: false,
      customerContactError: 'Valid 8 digit mobile number is required',
      customerEmail: '',
      customerEmailValidation: false,
      customerEmailError: 'Valid email is required',
      multipleDeceased: 'No',
      agentContactError: 'Valid 8 digit mobile number is required',
      agentContactValidation: false,
      deceasedName: '',
      deceasedAge: '',
      deceasedGender: '',
      deceasedNiche: '',
      deceasedPedestal: '',
      deceasedAngling: '',
      departedDate: '',
      deceasedGenderError: 'Valid name is required',
      deceasedGenderValidation: false,
      serviceDesCheckbox: [],
      firstAgree: '',
      serviceType: '',
      serviceTypeDetail: '',
      remarks: '',
      title: '',
      agentName: '',
      agentCode: '',
      agentContact: '',
      otpSentToPhone: '',
      enteredOTP: '',
      enteredOTPError: 'Valid otp is required',
      enteredOTPValidation: false,
      customFields: [],
      event: {},
      timeSlotDuration: '',
      secondAgree: '',
      existingBookingWarning: false,
      pageNotFound: false,
      enableNextPhaseCustomer: false,
      enableNextPhaseServiceDetail: false,
      timeCountDown: 0,
      hasAnotherBooking: false,
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/frontend/logo-orange-yellow-svg.svg'),
      mobLogoImg: require('@/assets/images/frontend/logo-orange-yellow-mobile.svg'),
      modalIcon: require('@/assets/images/frontend/modal_icon.svg'),
      isPhoneHidden: false,
      isEmailHidden: true,
      customFieldsLength: true,
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', maxDate: 'today', disableMobile: true,
      },
      flatPickrConfigWithTime: {
        wrap: true, enableTime: true, dateFormat: 'd/m/Y, H:i', disableMobile: true,
      },
      flatPickrConfigWithoutTime: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true, minDate: new Date().fp_incr(2),
      },
      flatPickrConfigTime: {
        wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true,
      },
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
      ],
      serviceOptions: [
        { text: 'Niche 骨灰福位', value: 'niche' },
        { text: 'Pedestal 神主牌位', value: 'pedestal' },
        { text: 'Ceremony 仪式', value: 'ceremony' },
      ],
      serviceDetailOptions: [
        { text: 'Interment of Urn 晋塔', value: 'interment of urn' },
        { text: 'Bone Picking 拾金/捡骨', value: 'bone picking' },
        { text: 'Urn Replacement 换瓮', value: 'urn replacement' },
        { text: 'Urn Storage 添福寿', value: 'urn storage' },
        { text: 'Paste Longevity Sticker 长生', value: 'paste longevity sticker' },
      ],
      serviceLocationOptions: [
        { text: 'Prayer Room 祭祀厅', value: 'prayer room' },
        { text: 'Anling Room 安灵房', value: 'anling room' },
        { text: 'Outside Blk A A栋外面', value: 'outside blk' },
        { text: 'Blk C Lvl 3 Training Hall Area C 栋3楼培训厅', value: 'blk c lvl' },
        { text: 'Blk C Lvl 3 Training Hall Area C栋3楼升天仪式房', value: 'blk c lvl 3' },
        { text: 'Tentage 后山', value: 'paste' },
      ],
      chantingServiceOptions: [
        { text: 'Standard Chanting 标准诵经 ($168)', value: 'standard' },
        { text: 'Ksitigarbha Sutra 地藏经 ($568)', value: 'ksitigarbha' },
      ],
      rentalPremisesOptions: [
        { text: '1–4/Hours 小时（$238）', value: '1-4/hours' },
        { text: '4–7/Hours 小时（$388）', value: '4-7/hours' },
      ],
      serviceRequests: [{
        title: '',
        category: '',
        categorySelected: false,
        workFlow: '',
        workFlowName: '',
        workFlowSelected: false,
        status: 'pending',
        sdStatus: 'pending',
        location: '',
        date: '',
        time: '',
        remarks: '',
        fileNo: '',
        specialForm: false,
        specialSDForm: false,
        lifeRemarks: '',
        lifeQuantity: 0,
        blessingRemarks: '',
        blessingQuantity: 0,
        specialFormName: '',
        requestForm: {},
        formValues: '',
        ownEngraving: false,
        files: JSON.stringify([]),
        formValidated: false,
        serviceDescription: [],
        showServiceDescription: false,
        serviceDescriptionValues: [],
        serviceDescriptionTitle: '',
      }],
      workFlowCategories: [],
      workFlows: [],
      serviceDescriptionData: {},
      serviceDescriptionMaster: {},
      serviceDescription: '',
      venueField: '',
      titleFields: '',
      specialWorkflows: [],
      specialSDWorkflows: [],
      specialServiceDescription: [],
      currentTabTitle: 'Service #1',
      currentTab: 0,
      checked: true,
      isSignature: false,
      customerSignature: '',
      pedestalSign: false,
      pedestalForm: {},
      serviceFormView: {},
      groupedCategory: [],
      formStatus: 'pending',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
      multipleDeceasedOptions: [
        { text: 'Yes 是', value: 'Yes' },
        { text: 'No 否', value: 'No' },
      ],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 20,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },
      uploadedFiles: [],
      pedestalFormValues: [],
      pedestalIndex: null,
      pedestalFormsCount: 0,
      cleaveOptions: {
        prefix: {
          numericOnly: true,
          prefix: '+65',
          blocks: [3, 8],
        },
      },
      required,
    }
  },
  computed: {
    previewPageComputation() {
      let valid = false
      this.serviceRequests.forEach(item => {
        if (item.workFlow) {
          if (this.specialWorkflows.includes(item.workFlow)) {
            if (item.ownEngraving) {
              if (item.files.length && item.formValidated) {
                valid = true
              } else {
                valid = false
              }

              // if (!this.uploadedFiles.length) {
              //   this.showFileUploadError = true
              // }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (item.formValidated) {
                valid = true
              } else {
                valid = false
              }
            }
          } else if (this.specialSDWorkflows.includes(item.workFlow)) {
            if (item.location) {
              valid = true
            } else {
              valid = false
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (item.date && item.time) {
              valid = true
            } else {
              valid = false
            }
          }
        } else {
          valid = false
        }
      })
      if (valid) {
        return false
      }
      return true
    },

    lastRequestFilled() {
      let valid = false
      const item = this.serviceRequests[this.serviceRequests.length - 1]
      if (item.workFlow) {
        if (this.specialWorkflows.includes(item.workFlow)) {
          if (item.ownEngraving) {
            if (item.files.length && item.formValidated) {
              valid = true
            } else {
              valid = false
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (item.formValidated) {
              valid = true
            } else {
              valid = false
            }
          }
        } else if (this.specialSDWorkflows.includes(item.workFlow)) {
          if (item.location) {
            valid = true
          } else {
            valid = false
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (item.date && item.time) {
            valid = true
          } else {
            valid = false
          }
        }
      } else {
        valid = false
      }
      if (valid) {
        return true
      }
      return false
    },
  },
  beforeMount() {
    this.$http.get('front-end/service-forms/respond-with/duty-officers-workflow')
      .then(response => {
        // this.dutyOfficerOptions = response.data.dutyOfficers || []
        // this.callLogTypes = response.data.callLogTypes || []
        let wfCats = []
        if (response.data.workFlowCats && response.data.workFlowCats.length) {
          wfCats = response.data.workFlowCats.filter(w => w.workFlows && w.workFlows.length)
        }
        this.workFlowCategories = wfCats
        // this.workFlowOptions = response.data.workFlowCats || []
        this.venueField = response.data.venueField ?? ''
        this.titleFields = response.data.titleFields ?? ''
        this.specialWorkflows = response.data.specialWorkflows.split(',')
        this.specialSDWorkflows = response.data.specialSDWorkflows.split(',')
        this.specialServiceDescription = response.data.specialServiceDescription.split(',')
        this.serviceFormView = response.data.serviceFormView || {}
        this.pedestalForm = response.data.pedestalForm || {}
        const { serviceDescription } = response.data

        // this.workFlowCategories.forEach(element => {
        //   this.workFlowOptions.push({
        //     category: element.name,
        //     name: null,

        //     _id: element._id,
        //   })
        //   element.workFlows.forEach(flow => {
        //     this.workFlowOptions.push({
        //       name: flow.name,

        //       _id: flow._id,
        //     })
        //   })
        // })

        if (serviceDescription) {
          if (serviceDescription.customFields.length) {
            const newArray = []
            serviceDescription.customFields.forEach(arrayItem => {
              const newObj = {}
              newObj.key = arrayItem.label
              newObj.type = arrayItem.type
              newObj.id = arrayItem.id
              newObj.parent = arrayItem.parent
              if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
                newObj.value = []
              } else if (arrayItem.type === 'title-and-text') {
                newObj.value = ''
                newObj.text = arrayItem.description
              } else if (arrayItem.type === 'increment') {
                newObj.value = +arrayItem.extraOptions[0].value
              } else {
                newObj.value = ''
              }
              newArray.push(newObj)
            })

            this.serviceDescriptionData = newArray
            // const clonedArray = JSON.parse(JSON.stringify(newArray))
            this.serviceDescriptionMaster = newArray
            this.serviceRequests[0].serviceDescription = JSON.parse(JSON.stringify(newArray))
          }

          this.customFields = serviceDescription.customFields || []
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  created() {
    window.addEventListener('scroll', this.stepperHandleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.stepperHandleScroll)
  },
  // mounted() {
  //   this.calcPosOfBox()
  // },

  methods: {
    processFile(file, index) {
      const acceptedTypes = 'image/jpeg,image/png,application/pdf'
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size > 20 * 1024 * 1024) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Image is too large',
            //     icon: 'AlertTriangleIcon',
            //     variant: 'danger',
            //   },
            // })
          } else {
            const exist = this.uploadedFiles[index].find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              // this.$refs.myVueDropzoneComponent.removeFile(element)
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                // const found = this.uploadedFiles.find(image => image === event.target.result)
                // if (found) {
                //   this.$refs.myVueDropzoneComponent.removeFile(element)
                //   this.$toast({
                //     component: ToastificationContent,
                //     props: {
                //       title: 'This File Already Exists',
                //       icon: 'AlertTriangleIcon',
                //       variant: 'danger',
                //     },
                //   })
                // } else {
                //   this.newFile = true
                //   const fileObj = {}
                //   fileObj.name = element.name
                //   fileObj.description = ''
                //   fileObj.data = event.target.result
                //   fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                //   fileObj.type = element.type
                //   this.uploadedFiles.push(fileObj)
                // }
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                fileObj.lastModified = element.lastModified
                this.uploadedFiles[index].push(fileObj)
                this.serviceRequests[index].files = JSON.stringify(this.uploadedFiles[index])
              }
            }
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unsupported file type',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    fileRemoved(file, key) {
      if (file.dataURL) {
        this.uploadedFiles[key] = this.uploadedFiles[key].filter(item => item.data !== file.dataURL)
      } else {
        this.uploadedFiles[key] = this.uploadedFiles[key].filter(item => item.name !== file.name)
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      const reference = `myVueDropzoneComponent${key}`
      if (!previewLength && this.$refs[reference] && this.$refs[reference].removeAllFiles) {
        this.$refs[reference].removeAllFiles()
      }

      this.serviceRequests[key].files = JSON.stringify(this.uploadedFiles[key])
    },
    async handleError(file, message, index) {
      await this.$nextTick()
      const reference = `myVueDropzoneComponent${index}`
      if (this.$refs[reference] && this.$refs[reference].length) {
        this.$refs[reference].removeFile(file)
      }
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    updateFormValidation(index, ownEngraving, values, purchaserSign) {
      this.serviceRequests[index].formValidated = true
      this.serviceRequests[index].formValues = values
      this.serviceRequests[index].ownEngraving = ownEngraving
      // this.serviceRequests[index].files = files
      this.pedestalSign = purchaserSign
      this.pedestalIndex = index
    },
    updateSignValidation(values) {
      this.serviceRequests[this.pedestalIndex].formValues = values
      this.$set(this.pedestalSign, this.pedestalIndex, true)
    },
    updateSignValidationWithIndex(values, index) {
      this.serviceRequests[index].formValues = values
      this.$set(this.pedestalSign, index, true)
    },
    updateAncestralSidebarActive(val) {
      this.isAncestralSidebarAction = val
    },
    getWorkFlows(id = null) {
      if (id) {
        const cat = this.workFlowCategories.find(o => o._id === id)
        if (cat) {
          return cat.workFlows
        }
      }

      return []
    },
    closeCustomerSignModal() {
      this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
    },
    openCustomerSignModal() {
      this.$root.$emit('bv::show::modal', 'customer-sign-modal', '')
    },
    clear() {
      this.$refs.signaturePad.clearSignature()
    },
    save() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.customerSignature = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
        this.isSignature = true
      }
    },
    changeFormOverview() {
      const index = this.pedestalSign.indexOf(false)
      this.ancestralFormPreview = this.ancestralFormPreview.map(() => false)
      this.$set(this.ancestralFormPreview, index, true)
      const reference = `previewComponent${index}`
      if (this.$refs[reference] && this.$refs[reference].length) {
        this.$refs[reference][0].goToSignElement('bottom')
        this.$refs[reference][0].openPurchaserSignModal()
      }
      this.gotoSignElementOneOfTwo('bottom')
    },

    openPedestalSignModal() {
      const index = this.pedestalSign.indexOf(false)
      const reference = `previewComponent${index}`
      if (this.$refs[reference] && this.$refs[reference].length) {
        this.$refs[reference][0].openPurchaserSignModal()
      }
    },

    async openAncestralSignModalWithIndex(index) {
      await this.$nextTick()
      this.ancestralFormPreview = this.ancestralFormPreview.map(() => false)
      this.$set(this.ancestralFormPreview, index, true)
      const reference = `previewComponent${index}`
      if (this.$refs[reference] && this.$refs[reference].length) {
        this.$refs[reference][0].goToSignElement('bottom')
      }
      this.gotoSignElementOneOfTwo('bottom')
    },
    calcPosOfBox() {
      this.bottomActionBlock = this.$refs.sticky_sidebar.getBoundingClientRect().y
      this.limitPosition = this.$refs.stepper_block.getBoundingClientRect().y
    },
    stepperHandleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true
        document.body.style.paddingTop = '60px'
      }
      if (this.limitPosition > window.scrollY && this.lastPosition > window.scrollY) {
        this.scrolled = false
        document.body.style.paddingTop = '0px'
      }

      if (this.lastPosition + window.outerHeight > this.bottomActionBlock + 100) {
        document.body.classList.add('hideStickyBar')
      } else {
        document.body.classList.remove('hideStickyBar')
      }
      this.lastPosition = window.scrollY
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-5', '')
    },
    getTimeFromDB() {
      if (this.start) {
        this.$http.post('front-end/activity/bookings/date-and-time-availability', { date: this.start, activity: this.$route.params.id })
          .then(response => {
            this.timeSlots = response.data.hours
            this.timeSlotDuration = response.data.timeSlotDuration
            if (!this.timeSlots.length) {
              this.startTimeError = 'No Time Slot Available for This Date'
              this.startTimeValidation = true
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },

    checkForCustomerSchedule() {
      if (this.startTime && (this.customerContact.length === 8 || this.validateEmail(this.customerEmail))) {
        let customer = ''
        if (this.isEmailHidden === true) {
          customer = this.customerContact
        } else {
          customer = this.customerEmail
        }
        this.$http
          .post('front-end/bookings/customer-schedule-availability', { date: this.start, time: this.startTime, customer })
          .then(response => {
            if (response.data.exists === true) {
              this.existingBookingWarning = true
              this.hasAnotherBooking = true
            } else {
              this.existingBookingWarning = false
              this.hasAnotherBooking = false
            }
          })
          .catch(() => {
            // this.existingBookingWarning = true
          })
      }
    },

    changePhase() {
      if (this.phase === 'customerDetail') {
        this.phase = 'serviceDetail'
      } else if (this.phase === 'serviceDetail') {
        this.phase = 'previewSignPhase'
      }

      window.scrollTo(0, 0)
    },

    updateSmsOnly() {
      this.smsOnly = !this.smsOnly
    },

    changePhaseFromBreadcrumb(phase) {
      this.phase = phase
      window.scrollTo(0, 0)
    },

    validateEmail(inputText) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (inputText.match(mailformat)) {
        return true
      }
      return false
    },

    validateCustomerName() {
      this.customerNameValidation = false
      this.enableNextPhaseCustomerDetail()
    },
    validateCustomerContact() {
      if (this.customerContact.length > 4 && this.customerContact.length <= 12) {
        if (this.customerContact.length === 12) {
          this.customerContactValidation = false
          this.enableNextPhaseCustomerDetail()
        } else {
          this.customerContactValidation = true
          this.enableNextPhaseCustomer = false
        }
      } else if (this.customerContact.length === 4) {
        this.customerContactValidation = false
        this.enableNextPhaseCustomer = false
      } else {
        this.customerContactValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    validateAgentContact() {
      if (this.agentContact.length > 4 && this.agentContact.length <= 12) {
        if (this.agentContact.length === 12) {
          this.agentContactValidation = false
        } else {
          this.agentContactValidation = true
        }
      } else if (this.agentContact.length === 4) {
        this.agentContactValidation = false
      } else {
        this.agentContactValidation = true
      }
    },

    validateCustomerEmail() {
      if (this.validateEmail(this.customerEmail)) {
        this.customerEmailValidation = false
        this.enableNextPhaseCustomerDetail()
      } else {
        this.customerEmailValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    validateStartTime() {
      if (this.startTime !== '') {
        this.startTimeValidation = false
        this.enableNextPhaseCustomerDetail()
        this.checkForCustomerSchedule()
      } else {
        this.startTimeValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    validateStartDate() {
      this.startTime = ''
      this.startTimeValidation = false
      if (this.start !== '') {
        this.startValidation = false
        this.enableNextPhaseCustomerDetail()
        this.getTimeFromDB()
      } else {
        this.startValidation = true
        this.enableNextPhaseCustomer = false
      }
    },

    enableNextPhaseCustomerDetail() {
      if (this.customerName.length !== 0 && this.customerContact.length === 12) {
        this.enableNextPhaseCustomer = true
      } else {
        this.enableNextPhaseCustomer = false
      }
    },

    enableNextPhaseServiceDetailDetail() {
      this.generateBookingTitle()
      this.customFields.every(obj => {
        if (!['file', 'title-and-text'].includes(obj.field)) {
          if (obj.required === 'yes' && obj.value.length === 0) {
            this.enableNextPhaseServiceDetail = false
            return false
          }
        }
        this.enableNextPhaseServiceDetail = true
        return true
      })
    },

    getUserOptions(data) {
      return data.filter(o => o.showInFrontend)
    },

    makeCheckboxToRadio() {
      const lastSelected = this.multipleDeceased.slice(-1)[0]
      if (lastSelected) {
        this.multipleDeceased = this.multipleDeceased.filter(code => code === lastSelected)
      }
    },
    changeRadioInput(sr, sd) {
      const lastSelected = this.serviceRequests[sr].serviceDescription[sd].value.slice(-1)[0]
      if (lastSelected) {
        this.serviceRequests[sr].serviceDescription[sd].value = this.serviceRequests[sr].serviceDescription[sd].value.filter(code => code === lastSelected)
      }
    },

    generateTitle(index) {
      this.uploadedFiles[index] = JSON.parse(this.serviceRequests[index].files) || []
      const workFlows = this.getWorkFlows(this.serviceRequests[index].category)
      const flow = workFlows.find(o => o._id === this.serviceRequests[index].workFlow)
      this.serviceRequests[index].workFlowName = flow.name
      this.serviceRequests[index].title = `Service #${index + 1}: ${flow.name}`
      this.currentTabTitle = `Service #${index + 1}: ${flow.name}`

      if (flow.approvalRequired === true) {
        this.serviceRequests[index].status = 'awaiting approval'
      } else {
        this.serviceRequests[index].status = 'pending'
      }

      if (this.specialWorkflows.includes(this.serviceRequests[index].workFlow)) {
        this.serviceRequests[index].specialForm = true
        this.serviceRequests[index].specialSDForm = false
        this.serviceRequests[index].specialFormName = 'Pedestal Engraving 神主牌刻碑'
        this.serviceRequests[index].workFlowSelected = true
        this.serviceRequests[index].location = ''
        this.serviceRequests[index].date = ''
        this.serviceRequests[index].time = ''
        this.serviceRequests[index].remarks = ''
        this.serviceRequests[index].fileNo = ''
        this.serviceRequests[index].showServiceDescription = false
        this.serviceRequests[index].serviceDescriptionValues = []
        this.serviceRequests[index].serviceDescriptionTitle = ''
      } else if (this.specialSDWorkflows.includes(this.serviceRequests[index].workFlow)) {
        this.serviceRequests[index].specialForm = false
        this.serviceRequests[index].specialSDForm = true
        this.serviceRequests[index].specialFormName = 'Paste Longevity Sticker'
        this.serviceRequests[index].workFlowSelected = false
        this.serviceRequests[index].showServiceDescription = false
        this.serviceRequests[index].serviceDescriptionValues = []
        this.serviceRequests[index].serviceDescriptionTitle = ''
      } else {
        this.serviceRequests[index].specialForm = false
        this.serviceRequests[index].specialSDForm = false
        this.serviceRequests[index].specialFormName = ''
        this.serviceRequests[index].workFlowSelected = false
      }
    },
    disableWorkflowSelection(tabIndex, id) {
      const index = this.serviceRequests.findIndex(o => o.workFlow === id)
      if (index !== -1) {
        if (index !== tabIndex) {
          return true
        }
      }

      return false
    },
    addServiceRequest() {
      this.serviceRequests.push({
        title: '',
        category: '',
        categorySelected: false,
        workFlow: '',
        workFlowName: '',
        workFlowSelected: false,
        status: 'pending',
        sdStatus: 'pending',
        location: '',
        date: '',
        time: '',
        remarks: '',
        fileNo: '',
        specialSDForm: false,
        lifeRemarks: '',
        lifeQuantity: 0,
        blessingRemarks: '',
        blessingQuantity: 0,
        specialForm: false,
        specialFormName: '',
        formValues: '',
        ownEngraving: false,
        files: JSON.stringify([]),
        formValidated: false,
        requestForm: {},
        serviceDescription: JSON.parse(JSON.stringify(this.serviceDescriptionMaster)),
        showServiceDescription: false,
        serviceDescriptionValues: [],
        serviceDescriptionTitle: '',
      })
      window.scrollTo(0, 0)
      // this.$nextTick(() => {
      //   this.$nextTick(() => {
      //     requestAnimationFrame(() => {
      //       this.currentTab = (this.serviceRequests.length - 1)
      //     })
      //   })
      // })
      // this.currentTab = (this.serviceRequests.length - 1)
      // console.log(this.currentTab)
    },
    deleteServiceRequest(index) {
      this.$swal({
        html: `<h4 class="p4a__alert-title">Remove service?</h4><span
       class="p4a__alert-subtitle">Are you sure you want to delete</span><br><strong class="p4a__alert-subtitle">${this.serviceRequests[index].title ? this.serviceRequests[index].title : `Service #${index + 1}`}</strong>?<br><span class="p4a__alert-subtitle">This action cannot be undone.</span>`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, I’m sure',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn p4a__service-danger-btn ml-1',
          cancelButton: 'btn p4a__service-common__outline-btn',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.serviceRequests.splice(index, 1)
            this.uploadedFiles.splice(index, 1)
            this.serviceRequests.forEach((service, key) => {
              this.generateTitle(key)
            })
          }
        })
    },
    showCategoryEditWarning(index) {
      this.$swal({
        html: '<h4 class="p4a__alert-title">Warning: Editing will result in the deletion of all entered data.</h4><p class="p4a__alert-subtitle">Are you sure you want to continue?</p>',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, I’m sure',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn p4a__service-common-btn ml-1',
          cancelButton: 'btn p4a__service-common__outline-btn',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.serviceRequests.splice(index, 1,
              {
                title: '',
                category: '',
                categorySelected: false,
                workFlow: '',
                workFlowName: '',
                workFlowSelected: false,
                status: 'pending',
                sdStatus: 'pending',
                location: '',
                date: '',
                time: '',
                remarks: '',
                fileNo: '',
                specialSDForm: false,
                lifeRemarks: '',
                lifeQuantity: 0,
                blessingRemarks: '',
                blessingQuantity: 0,
                specialForm: false,
                specialFormName: '',
                formValues: '',
                ownEngraving: false,
                files: JSON.stringify([]),
                formValidated: false,
                requestForm: {},
                serviceDescription: JSON.parse(JSON.stringify(this.serviceDescriptionMaster)),
                showServiceDescription: false,
                serviceDescriptionValues: [],
                serviceDescriptionTitle: '',
              })
          }
        })
    },
    tabChanged() {
      this.currentTab = (this.serviceRequests.length - 1)
      this.currentTabTitle = this.serviceRequests[this.currentTab].title ? this.serviceRequests[this.currentTab].title : `Service #${this.currentTab + 1}`
    },
    changeDropdownTitle(index) {
      this.currentTab = index
      this.currentTabTitle = this.serviceRequests[index].title ? this.serviceRequests[index].title : `Service #${index + 1}`
    },
    async goToSignElement(pos) {
      await this.$nextTick()
      if (pos === 'bottom') {
        this.isServiceTypeSignTopToolTip = false
        this.ancestralNoticeSignBoxFocus = true
      } else {
        this.isServiceTypeSignTopToolTip = true
        this.ancestralNoticeSignBoxFocus = false
      }
      const element = this.$refs.signDiv
      if (element) {
        if (pos === 'bottom') {
          const top = element.offsetTop - 135
          window.scrollTo({
            top,
            behavior: 'smooth',
          })
        } else {
          window.scrollTo({
            top: 135,
            behavior: 'smooth',
          })
        }
      }
    },
    async gotoSignElementOneOfTwo(pos) {
      await this.$nextTick()
      if (pos === 'bottom') {
        this.formOneMoveLength = 15
        this.ancestralSignBoxFocus = true
      } else {
        this.formOneMoveLength = 59.7
        this.ancestralSignBoxFocus = false
      }
    },
    makeAncestralFormPreview(index) {
      this.ancestralFormPreview = this.ancestralFormPreview.map(() => false)
      this.$set(this.ancestralFormPreview, index, true)
    },
    makePreviewPage() {
      const groupByCategory = this.serviceRequests.reduce((group, request) => {
        const { category } = request
        // eslint-disable-next-line no-param-reassign
        group[category] = group[category] ?? []
        group[category].push(request)
        return group
      }, {})
      this.hasSpecialForm = false
      this.tooltipPosition = 54.5
      this.formStatus = 'pending'
      const titleArray = this.titleFields.split(',')
      this.pedestalFormsCount = 0
      this.ancestralFormPreview = []
      this.pedestalSign = []
      this.pedestalFormValues = []
      // const serviceDescription = this.serviceDescriptionMaster
      Object.keys(groupByCategory).forEach(group => {
        groupByCategory[group].forEach(req => {
          // eslint-disable-next-line no-param-reassign
          // req.serviceDescription = this.serviceDescription
          if (this.specialWorkflows.includes(req.workFlow)) {
            this.hasSpecialForm = true
            this.ancestralFormPreview.push(false)
            this.pedestalFormValues.push(JSON.parse(req.formValues))
            // this.pedestalSign.push(false)
            this.tooltipPosition = 35
            this.pedestalFormsCount += 1
            const fValues = JSON.parse(req.formValues)
            const signValue = fValues.find(o => o.key === 'purchaserSign')
            if (signValue.value) {
              this.pedestalSign.push(true)
            } else {
              this.pedestalSign.push(false)
            }
          }
          const serviceDescriptionValues = []
          let showServiceDescription = false
          let serviceDescriptionTitle = ''
          // eslint-disable-next-line no-param-reassign
          req.sdStatus = 'pending'
          req.serviceDescription.forEach(obj => {
            if (!obj.parent) {
            // sortedData.push(obj)
              const children = req.serviceDescription.filter(o => o.parent === obj.id)
              if (children.length) {
                const childrenArray = []
                children.forEach(opt => {
                  let hasValue = false
                  if (opt.type === 'title-and-text') {
                    const child = {
                      id: opt.id,
                      parent: opt.parent,
                      key: opt.key,
                      type: opt.type,
                      text: opt.text,
                      value: '',
                    }
                    childrenArray.push(child)
                  } else if (Array.isArray(opt.value)) {
                    if (opt.value.length) {
                      hasValue = true
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: opt.value,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.value.join(', ')}`
                        } else {
                          serviceDescriptionTitle += `+${opt.value.join(', ')}`
                        }
                      }
                    }
                  } else if (opt.value) {
                    if (opt.type === 'increment' && Number(opt.value) !== 0) {
                      hasValue = true
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: `x${opt.value}`,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.key} x${opt.value}`
                        } else {
                          serviceDescriptionTitle += `+${opt.key} x${opt.value}`
                        }
                      }
                    } else {
                      hasValue = true
                      const child = {
                        id: opt.id,
                        parent: opt.parent,
                        key: opt.key,
                        type: opt.type,
                        value: opt.value,
                      }
                      childrenArray.push(child)
                      showServiceDescription = true
                      if (titleArray.includes(obj.key)) {
                        if (serviceDescriptionTitle === '') {
                          serviceDescriptionTitle += `${opt.key} ${opt.value}`
                        } else {
                          serviceDescriptionTitle += `+${opt.key} ${opt.value}`
                        }
                      }
                    }
                  }

                  if (this.specialServiceDescription.includes(opt.key) && hasValue) {
                    // eslint-disable-next-line no-param-reassign
                    req.sdStatus = 'awaiting approval'
                  }
                })
                const parent = {
                  id: obj.id,
                  parent: obj.parent,
                  key: obj.key,
                  type: obj.type,
                  text: obj.text,
                  value: obj.value,
                  children: childrenArray,
                }
                serviceDescriptionValues.push(parent)
              } else {
                let hasValue = false
                // eslint-disable-next-line no-lonely-if
                if (obj.type === 'title-and-text') {
                  const parent = {
                    id: obj.id,
                    parent: obj.parent,
                    key: obj.key,
                    type: obj.type,
                    text: obj.text,
                    value: '',
                  }
                  serviceDescriptionValues.push(parent)
                } else if (Array.isArray(obj.value)) {
                  if (obj.value.length) {
                    hasValue = true
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: obj.value,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.value.join(', ')}`
                      } else {
                        serviceDescriptionTitle += `+${obj.value.join(', ')}`
                      }
                    }
                  }
                } else if (obj.value) {
                  if (obj.type === 'increment' && Number(obj.value) !== 0) {
                    hasValue = true
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: `x${obj.value}`,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.key} x${obj.value}`
                      } else {
                        serviceDescriptionTitle += `+${obj.key} x${obj.value}`
                      }
                    }
                  } else {
                    hasValue = true
                    const parent = {
                      id: obj.id,
                      parent: obj.parent,
                      key: obj.key,
                      type: obj.type,
                      value: obj.value,
                    }
                    serviceDescriptionValues.push(parent)
                    showServiceDescription = true
                    if (titleArray.includes(obj.key)) {
                      if (serviceDescriptionTitle === '') {
                        serviceDescriptionTitle += `${obj.key} ${obj.value}`
                      } else {
                        serviceDescriptionTitle += `+${obj.key} ${obj.value}`
                      }
                    }
                  }
                }

                if (this.specialServiceDescription.includes(obj.key) && hasValue) {
                  // eslint-disable-next-line no-param-reassign
                  req.sdStatus = 'awaiting approval'
                }
              }
            }
          })
          // eslint-disable-next-line no-param-reassign
          req.showServiceDescription = showServiceDescription
          // eslint-disable-next-line no-param-reassign
          req.serviceDescriptionValues = serviceDescriptionValues
          // eslint-disable-next-line no-param-reassign
          req.serviceDescriptionTitle = serviceDescriptionTitle
          // this.generateTitle(this.selectedIndex)

          if (req.status === 'awaiting approval' || req.sdStatus === 'awaiting approval') {
            this.formStatus = 'awaiting approval'
          }
        })
      })

      if (this.hasSpecialForm) {
        this.serviceFormPreview = false
        this.$set(this.ancestralFormPreview, 0, true)
      } else {
        this.serviceFormPreview = true
      }
      this.groupedCategory = groupByCategory
      this.phase = 'previewSignPhase'
      window.scrollTo(0, 0)
    },
    getCategoryName(id) {
      const cat = this.workFlowCategories.find(o => o._id === id)
      if (cat) {
        return cat.name
      }

      return ''
    },
    showPDFOverlay() {
      this.$bvModal.show('submit-sign-form-modal')
    },
    submitFinalForm() {
      const formData = new FormData()

      formData.append('customerName', this.customerName)
      formData.append('customerContact', this.customerContact)
      formData.append('customerEmail', this.customerEmail)
      formData.append('deceasedName', this.deceasedName)
      formData.append('deceasedAge', this.deceasedAge)
      formData.append('deceasedGender', this.deceasedGender)
      formData.append('deceasedNiche', this.deceasedNiche)
      formData.append('deceasedPedestal', this.deceasedPedestal)
      formData.append('deceasedAngling', this.deceasedAngling)
      formData.append('departedDate', this.departedDate)
      formData.append('agentName', this.agentName)
      formData.append('agentCode', this.agentCode)
      formData.append('agentContact', this.agentContact)
      formData.append('formStatus', this.formStatus)
      formData.append('sendOptionType', this.smsOnly ? 'phone' : 'email')
      formData.append('url', window.location.origin)
      formData.append('customerSign', this.customerSignature)
      // eslint-disable-next-line no-plusplus
      formData.append('serviceRequests', JSON.stringify(this.groupedCategory))

      this.$http.post('front-end/service-forms/store/data', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            await this.sendMessage(response.data.chatID, response.data.data)
            this.$swal({
              html: '<h4 class="p4a__alert-title">Successful Form Submission</h4><p class="p4a__alert-subtitle">The service form is submitted for Duty Officer to review. A confirmation text will be send to you by mobile number.</p>',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/success.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              cancelButtonText: 'View Entry',
              allowOutsideClick: false,
              confirmButtonText: 'Got it',
              customClass: {
                confirmButton: 'btn p4a__service-common-btn mr-1',
                cancelButton: 'btn p4a__service-common__outline-btn',
              },
              buttonsStyling: false,
            })
              .then(() => {
                this.$router.push({ name: 'appointments-front-end' })
                document.body.style.paddingTop = '0px'
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    generateBookingTitle() {
      let title = ''
      this.activity.customFields.forEach((arrayItem, key) => {
        if (arrayItem.displayInCalendar === 'yes' && this.customFields[key].value !== '') {
          const val = this.customFields[key].value
          if (Array.isArray(val)) {
            val.forEach(arrVal => {
              const calVal = arrayItem.extraOptions.find(o => o.key === arrVal)
              title = `${title}${arrayItem.label} ${calVal.value} `
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            if (arrayItem.extraOptions.length) {
              const calVal = arrayItem.extraOptions.find(o => o.key === val)
              if (calVal) {
                title = `${title}${arrayItem.label} ${calVal.value} `
              } else {
                title = `${title}${arrayItem.label} ${val} `
              }
            } else {
              title = `${title}${arrayItem.label} ${val} `
            }
          }
        }
      })
      this.title = title
    },

    sendConfirmationOTP(type) {
      if (type === 'agent') {
        this.otpSentToPhone = this.agentContact
      } else {
        this.otpSentToPhone = this.customerContact ? this.customerContact : this.customerEmail
      }

      this.$http
        .post('front-end/bookings/send-otp', { otpSentToPhone: this.otpSentToPhone, customerName: this.customerName })
        .then(response => {
          this.timeCountDown = 60
          this.$root.$emit('bv::show::modal', 'modal-3', '')
          this.countDownTimer()
          if (response.data.alert) {
            // eslint-disable-next-line no-alert
            alert(response.data.alert)
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    resendConfirmationOTP() {
      this.$http
        .post('front-end/bookings/send-otp', { otpSentToPhone: this.otpSentToPhone, customerName: this.customerName })
        .then(response => {
          this.timeCountDown = 60
          this.countDownTimer()

          if (response.data.alert) {
            // eslint-disable-next-line no-alert
            alert(response.data.alert)
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    countDownTimer() {
      if (this.timeCountDown > 0) {
        setTimeout(() => {
          this.timeCountDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    sendMessage(chatID, form) {
      let rawMessage = `<p><strong>New Service Form <a href="${window.location.origin}/operations/service-forms/${form._id}/show" rel="noopener noreferrer" target="_blank">${form.stringID} received</a></strong></p><p><br></p><p>Customer: ${form.customerName || '-'} ${form.customerContact || form.customerEmail}</p><p>Agent: ${form.agentName || '-'} ${form.agentContact || '-'}</p>`
      this.serviceRequests.forEach(item => {
        rawMessage += `<p><br></p><p>${item.workFlowName || '-'} - ${item.date || '-'} ${item.time ? this.dateFormatFromTimeString(item.time) : '-'}</p>`
      })
      rawMessage += `<p><br></p><p>Duty officer, <a href="${window.location.origin}/operations/service-forms/${form._id}/show" rel="noopener noreferrer" target="_blank">please review and approve this Service Form</a>.</p>`
      const telegramURL = 'https://api.telegram.org/bot'
      const botToken = process.env.VUE_APP_TELEGRAM_BOT_TOKEN
      // const chatID = '-1001214367772'
      // const pageURL = `[For Service Request ${this.serviceRequestString}](${window.location.href})`
      // console.log(window.location.href)
      const message = rawMessage
        .replace(/(<p>)/igm, '')
        .replace(/<\/p>/igm, '%0A')
        .replace(/<br>/igm, '')
        .replace(/&amp;/igm, '%26amp;')
      // const message = encodeURI(replacedText)
      const finalURL = `${telegramURL}${botToken}/sendMessage?chat_id=${chatID.trim()}&text=${message}&parse_mode=HTML`
      this.$http.get(finalURL)
        .then(result => {
          console.log(result)
        })
        .catch(error => {
          console.log(error)
        })
    },

    submitFullForm() {
      this.$refs.frontActivityBookingCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('customerName', this.customerName)
          formData.append('customerContact', this.customerContact)
          formData.append('customerEmail', this.customerEmail)
          formData.append('activityType', this.$route.params.id)
          formData.append('startDate', this.start)
          formData.append('startTime', this.startTime)
          formData.append('timeSlotDuration', this.timeSlotDuration)
          formData.append('remarks', this.remarks)
          formData.append('title', this.title)
          formData.append('hasAnotherBooking', this.hasAnotherBooking)
          formData.append('agentName', this.agentName)
          formData.append('agentCode', this.agentCode)
          formData.append('agentContact', this.agentContact)
          formData.append('otpSentToPhone', this.otpSentToPhone)
          formData.append('enteredOTP', this.enteredOTP)
          formData.append('customFields', JSON.stringify(this.customFields))

          this.$http.post('front-end/bookings/store/activity', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  html: `<h4 class="p4a__alert-title">Successfully Registered!</h4>
              <br><span class="p4a__alert-subtitle">You will be receiving a confirmation message to be sent to ${this.customerEmail || this.customerContact} shortly.
                    您将很快收到一封确认邮件，该邮件将发送至 ${this.customerEmail || this.customerContact}</span>`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/frontend/success_icon.svg'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Ok, got it 知道了',
                  customClass: {
                    confirmButton: 'btn p4a__service-common-btn',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointments-front-end' })
                      document.body.style.paddingTop = '0px'
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'customerEmail') {
                    this.customerEmailError = validationError.msg
                    this.customerEmailValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'startDate') {
                    this.startError = validationError.msg
                    this.startValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'startTime') {
                    this.startTimeError = validationError.msg
                    this.startTimeValidation = true
                    this.$root.$emit('bv::hide::modal', 'modal-3', '')
                    this.enableNextPhaseCustomer = false
                    this.phase = 'customerDetail'
                  } else if (validationError.param === 'enteredOTP') {
                    this.enteredOTPError = validationError.msg
                    this.enteredOTPValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.enteredOTPError = error.data.message
                this.enteredOTPValidation = true
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-group-toggle [class*="btn-outline-"]:not(:last-child) {
  border-right-width: 1px !important;
}
.inlineElement {
  display: flex;
}
.clickableText {
  cursor: pointer;
  color: #104d9d;
}
ul.nav.wrap-border.save-nav {
  position: inherit;
  left: 0;
}
.frontend {
  .page-content {
    background: #f5f0df !important;
    background-size: 100% auto;
    .activities {
      &:before {
        background: unset;
      }
      &:after {
        background: unset;
      }
    }
  }
}
.service-request-card {
  margin: 40px 0 40px !important;
}
.sd-step-wrapper {
  padding: 40px 0;
}
.sd-step-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #000000;
}
.sd-step-opacity {
  opacity: 0.3;
  pointer-events: none;
}
.sd-full-opacity {
  opacity: 1;
  cursor: pointer;
}
.sd-service-single-block {
  background: #e0e8f2;
  border-radius: 6px;
  padding: 20px;
  margin: 10px 0;
}
.sd-option-button {
  display: block;
}
.sd-option-button .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #104d9d;
  color: #ffffff;
}
.sd-option-button .btn-outline-primary:not(:disabled):not(.disabled).active,
.sd-option-button .btn-outline-primary:not(:disabled):not(.disabled):focus {
  background-color: #104d9d;
  color: #ffffff;
}

.sd-service-description-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
}

.sd-single-service-description {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  background: #f5f8fb;
  border-radius: 6px;
  margin: 5px 0;
  p {
    margin-bottom: 0;
    margin: auto 0;
  }
  .increment-decrement-block {
    margin: 0;
  }
}
.ps-avatar-bg {
  background: rgba(255, 255, 255, 0.3);
}

.ps-cd-header-text-section {
  font-family: Open Sans;
  margin-left: 20px;
  p {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.4px;
    text-transform: capitalize;
  }
  h5 {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
  }
}
.ps-main-content-wrapper {
  background: #9fb8d8;
  padding: 15px;
  position: relative;
}
.ps-tooltip-card {
  position: absolute;
  left: -28%;
  &::after {
    content: "";
    position: absolute;
    top: 40%;
    left: 100%;
    margin-top: -5px;
    border-width: 7px;
    border-style: solid;
    // box-shadow: 0px 4px 24px 0px #D2DCEA;
    border-color: transparent transparent transparent #fff;
  }
  // bottom: 62.7%;
}
// .ps-tooltip-position-adjust{
//     bottom: 20% !important;
// }
.ps-tooltip-card .card .card-body {
  padding: 0 !important;
  box-shadow: 0px 4px 24px 0px #d2dcea;
}

.ps-tooltip-card .card .card-body .card-text {
  padding: 17px;
  p {
    margin-bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
.ps-tooltip-card .card .card-body .card-text .ps-tooltip-button {
  background: rgba(255, 159, 67, 0.12) !important;
  border: none !important;
  color: #ff9f43 !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.366667px;
  padding: 8px 19px;
  margin-top: 20px !important;
  &:hover {
    background: #ff9f43 !important;
    color: #fff !important;
  }
}
.ps-tooltip-card .card .card-body .card-header {
  padding: 7px 16px;
  p {
    margin-bottom: 0;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #ffffff;
  }
}

.p4a__sign-modal .modal {
  .modal-content {
    header {
      .modal-header {
        h5 {
          font-family: Open Sans;
          font-size: 18px !important;
          line-height: 22px;
          font-weight: 600;
          color: rgba(106, 94, 50, 1) !important;
        }
      }
    }
  }
}
.ps-main-content-wrapper .card .card-body {
  padding: 20px;
}
.ps-simple-table .table th,
.ps-simple-table .table td {
  padding: 3px 0 !important;
}
.service-table__td-black .table td:first-child {
  color: #000 !important;
}
.service-table__td-black .table td:nth-child(3) {
  color: #000 !important;
}
.page-content .events .card-body .ps-service-type-block {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #cbcbcb;
  .ps-service-type-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    background: #4b4b4b;
    padding: 10px 20px;
    margin: 0;
  }
  .ps-service-type-name {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background: #d2dcea;
    padding: 6px 20px;
  }
}

.ps-service-des-block {
  padding: 10px 20px;
}
.ps-service-des-checkbox-block {
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin: 0;
  }
}

.ps-service-des-content-block {
  padding: 0px 0px 0px 28px;
}

.ps-service-des-bg-block {
  background: rgba(186, 191, 199, 0.12);
  padding: 10px 20px;
  margin-bottom: 10px;
}
.ps-notice-left-section {
  padding: 20px;
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 15px;
  }
  .ps-notice-field-value {
    color: #104d9d !important;
    border-bottom: 1px solid #000000;
  }
}
.ps-notice-right-section {
  padding: 10px;
  font-family: "Montserrat";
  font-style: normal;
  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #000000;
  }
  ul {
    padding-left: 20px;
    list-style: disc;
  }
  ul > li {
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    padding: 3px 0;
  }
}
.ps-authorized-signature {
  font-family: "Montserrat";
  font-style: normal;
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
    color: #000000;
  }
}
.ps-authorized-signature .ps-signature-date-block p {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 0;
  color: #000000;
}
.ps-bottom-box {
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 8px;
  }
}
.page-content hr {
  margin: 20px 0;
  border-color: #d2dcea;
}

.breadcrumb-item__width-adjust {
  min-width: 270px;
  @media (max-width: 991.98px) {
    min-width: 100%;
  }
  @media (max-width: 767.98px) {
    min-width: 100%;
  }
}

.submit-form_mobile {
  .mobile-sidebar__content-section {
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    padding: 15px 20px;
    .mobile-sidebar__top-content {
      p {
        color: #000;
        text-align: right;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .mobile_sidebar-btn {
        width: 38px;
        height: 38px;
        padding: 9px;
        border-radius: 20px;
        background-color: #f5f8fb !important;
        color: #104d9d !important;
        border: 3px solid #f5f8fb !important;
        position: relative !important;
        &:hover {
          box-shadow: none !important;
        }
        + .mobile_sidebar-btn {
          margin-left: 10px;
          &::before {
            width: 15px;
            height: 2px;
            background: #104d9d;
            top: 15px;
            margin-left: -25px;
            content: "";
            position: absolute;
          }
        }
      }
      .mobile_sidebar-btn_active {
        background-color: #104d9d !important;
        color: #fff !important;
      }
      .mobile_sidebar-btn_click-active {
        border: 3px solid #fde666 !important;
        box-shadow: 0px 0px 5px 0px #fde666 !important;
      }
    }
    .mobile-sidebar__bottom-content {
      display: flex;
      justify-content: space-between;
      .form-title_section {
        max-width: 246px;
        p {
          color: #000;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          text-transform: capitalize;
          margin-bottom: 0;
        }
      }
      .form-btn_section {
        width: 100%;
        height: 50%;
        button {
          display: block;
          margin-left: auto;
          padding: 8px 19px;
          align-items: center;
          font-family: Montserrat;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.367px;
        }
      }
    }
  }
  .mobile-sidebar__action-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 20px 20px;
    p {
      color: #104d9d;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.4px;
      margin-bottom: 0;
      width: 100%;
      cursor: pointer;
    }
    button {
      font-family: Montserrat;
      font-size: 14px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.4px;
      width: 100%;
      padding: 10px 18px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .mobile_res-first-step {
    h3 {
      line-height: 21px !important;
      margin-bottom: 5px !important;
    }
    p {
      font-size: 14px !important;
      line-height: 24px !important;
      margin-bottom: 20px !important;
    }
    .col-form-label {
      padding: 0 0 5px 0 !important;
    }
  }
  .sd-step-wrapper {
    padding: 20px !important;
  }
  .mobile_res-white-bg {
    background: #fff !important;
  }
  .mobile-input__value-adjust {
    font-size: 14px;
  }
  .mobile_res-second-step-title {
    font-size: 15px !important;
    line-height: 24px !important;
  }
}
@media (max-width: 430px) {
  .d-form_bg-white {
    background: #fff;
    padding: 0 !important;
  }
}
</style>
